import * as React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'

import { useContract } from '../hooks'
import { ConfigContext, ConfigContextType } from './configProvider'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { Loading, Token } from '../interfaces/interfaces'
import _ from 'lodash'
import { useLoaderDispatch } from './loaderContext'
import { ToastContext } from './toastContext'
import { u256ToNum } from '../pages/purchase/txHelpers'
import { listings } from '../web3'

export interface UpdateToken {
  ToTokenId?: number
  tokenIds?: number[]
  shouldToast?: boolean
}

export type TLType = {
  mintedTokens: Token[]
  loadingMinted: Loading

  updateTokenIds: ({ ToTokenId, tokenIds, shouldToast }: UpdateToken) => void

  handleRefreshTokenOwnerShip: () => void

  lastUpdated: number
}

export const TokenListContext = createContext<TLType | undefined>(undefined)

async function getTokensWithListingData(listingPromises: Promise<any>[], newTokens) {
  try {
    const listings = await Promise.all(listingPromises)
    listings?.forEach((listing, index) => {
      if (listing) {
        newTokens[index] = {
          ...newTokens[index],
          listing: {
            owner: listing?.owner,
            recipient: listing.recipient,
            backedToken: listing?.backedToken,
            price: u256ToNum(listing?.price),
            expiryTime: u256ToNum(listing?.expiryTime),
            listed: listing.listed,
          },
        }
      }
    })
    return newTokens
  } catch (e) {
    return Promise.resolve()
  }
}

async function getTokenErc20Data(backedTokenPromises: Promise<any>[], newTokens, network: ConfigContextType['networkConfig']) {
  const backedTokens = await Promise.all(backedTokenPromises)
  backedTokens.forEach((backedToken, index) => {
    if (backedToken) {
      newTokens[index] = {
        ...newTokens[index],
        tokenConfig: Object.values(network?.tokens).find((token) => {
          return token?.address === backedToken || token?.proxyAddress === backedToken
        }),
      }
    } else {
      console.log('backed token not found')
    }
  })
  return newTokens
}

async function getTokensWithMetaData(tokenMetadata: Promise<any>[], newTokens) {
  // skip if tokens already have metadata as metadata will not change.
  if (newTokens?.[0]?.metadata) return Promise.resolve(newTokens)
  const metadata = await Promise.all(tokenMetadata)
  for (let i = 0; i < metadata.length; i++) {
    newTokens[i] = {
      ...newTokens[i],
      tokenId: i,
      metadata: metadata[i],
    }
  }
  return newTokens
}

const TokenListProvider = ({ children }) => {
  // context states
  const { account, chainId } = useWeb3React()
  const { setLoading } = useLoaderDispatch()

  const [mintedTokens, setMintedTokens] = useState<Token[]>(undefined)

  // loading states
  const [loadingMinted, setLoadingMinted] = useState<Loading>(false)
  const [lastUpdated, setLastUpdated] = useState<number>(new Date().getTime())

  // hooks && context
  const { networkConfig } = useContext(ConfigContext)
  const nftContract = useContract(networkConfig?.contracts?.nftConfig)
  const vaultContract = useContract(networkConfig?.contracts?.vaultConfig)
  const marketPlaceContract = useContract(networkConfig?.contracts?.marketPlaceConfig)
  const { showToast } = useContext(ToastContext)

  useEffect(() => {
    let active = true
    load()
    return () => {
      active = false
    }

    async function load() {
      if (!nftContract || !marketPlaceContract) return
      console.log(nftContract, marketPlaceContract)
      try {
        const lastMintIdHex = await nftContract?.mintingId()
        const nextMintId = ethers.BigNumber.from(lastMintIdHex).toNumber()
        if (!active) {
          return
        }
        await UpdateMintedTokens({ upToTokenId: nextMintId })
      } catch (e) {
        console.log(e)
      }
    }
  }, [nftContract, marketPlaceContract, chainId])

  useEffect(() => {
    setLoading(account && (!nftContract || !marketPlaceContract))
  }, [chainId, nftContract, marketPlaceContract])

  const UpdateMintedTokens = async ({
    upToTokenId,
    tokenIds,
    shouldToast = true,
  }: {
    upToTokenId?: number
    tokenIds?: number[]
    shouldToast?: boolean
  }) => {
    setLoadingMinted(true)
    const listingPromises = []
    const backingErc20 = []

    let newTokens: Token[]
    if (!nftContract || !marketPlaceContract) {
      throw new Error('NFT or marketplace contract(s) or  not loaded')
    }

    // metadata promise results in array of metadata including each token's metadata

    const metaDataUrl = `https://633b339f471b8c39557e75f8.mockapi.io/metadata?page=1&limit=${upToTokenId}`
    const response = await fetch(metaDataUrl)
    const data = await response.json()

    if (tokenIds?.length > 0) {
      newTokens = _.cloneDeep(mintedTokens)
    } else if (upToTokenId > 0) {
      newTokens = _.fill(Array(upToTokenId), undefined)
      newTokens = newTokens.map((_, index) => {
        return {
          tokenId: index,
          metadata: data[index],
          tokenConfig: undefined,
          listing: undefined,
          isOwned: undefined,
          isBurned: false,
          premium: undefined,
          erc20Equivalence: undefined,
          owner: undefined,
        }
      })
    } else {
      setLoadingMinted(false)
      setMintedTokens([])
      throw new Error('No tokens minted')
    }

    // if tokenIds are passed, update metadata for those tokens only
    newTokens.forEach((newToken) => {
      const shouldUpdate = !tokenIds || tokenIds.includes(newToken.tokenId)

      listingPromises.push(shouldUpdate ? listings(marketPlaceContract)(BigNumber.from(newToken.tokenId)) : undefined)

      backingErc20.push(shouldUpdate ? nftContract?.getBackedToken(newToken.tokenId) : undefined)
    })

    newTokens = await getTokensWithListingData(listingPromises, newTokens)
    newTokens = await getTokenErc20Data(backingErc20, newTokens, networkConfig)
    newTokens = await updateTokenOwnership(newTokens)
    newTokens = await updateTokenBackingAmount(newTokens)
    newTokens = newTokens.filter((token) => {
      return !token?.isBurned
    })
    setMintedTokens(newTokens)
    setLastUpdated(new Date().getTime())
    setLoadingMinted(false)

    if (tokenIds?.length) {
      // set toast
      if (shouldToast)
        showToast({
          type: 'success',
          message: `Updated Token ID: ${tokenIds.join(', ') || ''}`,
          duration: 5000,
          position: 'bottom-center',
        })
    }
  }

  const updateTokenOwnership = async (tokens: Token[]) => {
    if (nftContract && tokens && tokens.length > 0) {
      for (const token of tokens) {
        if (!token) continue
        if (token.tokenConfig?.address === ethers.constants.AddressZero.toLowerCase()) {
          token.isBurned = true
          token.isOwned = false
          continue
        }
        const owner = await nftContract?.ownerOf(token.tokenId)
        token.isOwned = token?.listing?.owner === account || owner === account
        token.owner = owner
      }
    }
    return tokens
  }

  const updateTokenBackingAmount = async (tokens: Token[]) => {
    if (nftContract && tokens && tokens.length > 0) {
      for (const token of tokens) {
        if (!token) continue
        if (token.isBurned) {
          token.erc20Equivalence = 0
          token.premium = 0
          continue
        }

        try {
          const amt = await nftContract?.getERC20Equivalence(token.tokenId)
          const premium = await vaultContract?.issuancePremiums(token.tokenId)

          token.erc20Equivalence = u256ToNum(amt)
          token.premium = u256ToNum(premium)
          // token.premium = ethers.BigNumber.from(premium).toNumber()
        } catch (e) {
          console.log('error fetching erc20 equivalence or premium', e)
        }
      }
    }
    return tokens
  }

  const handleRefreshTokenOwnerShip = async () => {
    let newTokens = await updateTokenOwnership(mintedTokens)
    setMintedTokens(newTokens)
  }

  return (
    <TokenListContext.Provider
      value={{
        mintedTokens: mintedTokens ?? [],
        loadingMinted,
        updateTokenIds: UpdateMintedTokens,
        lastUpdated,
        handleRefreshTokenOwnerShip,
      }}
    >
      {children}
    </TokenListContext.Provider>
  )
}

export default TokenListProvider

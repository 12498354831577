import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import "./NFTtable.scss";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

export const NFTtabel = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [data, setData] = React.useState([]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    setData(altData);
  }, []);

  return (
    <div className="nft-table">
      <TableContainer className="nft-table-wrapper">
        <Table className="nftTable-container" aria-label="NFT table">
          <TableHead>
            <TableRow className="nftTable-header">
              <TableCell align="center">View</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Token</TableCell>
              <TableCell>Metal Type</TableCell>
              <TableCell>AGX</TableCell>
              <TableCell>AUX</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, i) => (
              <TableRow key={i} className="nftTable-body">
                <TableCell className="event-activity-text" align="center">
                  <span role={"button"}>
                    <RemoveRedEyeIcon />
                  </span>
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  {row.type === "Gold" && (
                    <div className="golden-tag">
                      {row.token ? row?.token.toUpperCase() : ""}
                    </div>
                  )}
                  {row.type === "Silver" && (
                    <div className="silver-tag">
                      {row.token ? row?.token.toUpperCase() : ""}
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  {row.type === "Gold" && (
                    <div className="golden-tag">{row.type}</div>
                  )}
                  {row.type === "Silver" && (
                    <div className="silver-tag">{row.type}</div>
                  )}
                </TableCell>
                <TableCell>{row.agx}</TableCell>
                <TableCell className="eventTable-date">{row.aux}</TableCell>
              </TableRow>
            ))}

            {data.length === 0 && (
              <TableRow className="eventTable-noData">
                <TableCell colSpan={6}>No data found</TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{ height: 53 * emptyRows }}
                className="eventTable-tableAdjust"
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow className="nft-table-footer">
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="table-pagination"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

const altData = [
  {
    id: "0113213554655456",
    token: "agx",
    type: "Silver",
    agx: "66323315",
    aux: "0",
  },
  {
    id: "0113213554655456",
    token: "aux",
    type: "Gold",
    aux: "66323315",
    agx: "0",
  },
  {
    id: "0113213554655456",
    token: "agx",
    type: "Silver",
    agx: "66323315",
    aux: "0",
  },
  {
    id: "0113213554655456",
    token: "aux",
    type: "Gold",
    aux: "66323315",
    agx: "0",
  },
  {
    id: "0113213554655456",
    token: "agx",
    type: "Silver",
    agx: "66323315",
    aux: "0",
  },
  {
    id: "0113213554655456",
    token: "aux",
    type: "Gold",
    aux: "66323315",
    agx: "0",
  },
  {
    id: "0113213554655456",
    token: "agx",
    type: "Silver",
    agx: "66323315",
    aux: "0",
  },
  {
    id: "0113213554655456",
    token: "aux",
    type: "Gold",
    aux: "66323315",
    agx: "0",
  },
  {
    id: "0113213554655456",
    token: "agx",
    type: "Silver",
    agx: "66323315",
    aux: "0",
  },
  {
    id: "0113213554655456",
    token: "aux",
    type: "Gold",
    aux: "66323315",
    agx: "0",
  },
  {
    id: "0113213554655456",
    token: "agx",
    type: "Silver",
    agx: "66323315",
    aux: "0",
  },
  {
    id: "0113213554655456",
    token: "aux",
    type: "Gold",
    aux: "66323315",
    agx: "0",
  },
  {
    id: "0113213554655456",
    token: "agx",
    type: "Silver",
    agx: "66323315",
    aux: "0",
  },
  {
    id: "0113213554655456",
    token: "aux",
    type: "Gold",
    aux: "66323315",
    agx: "0",
  },
];

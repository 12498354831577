import React from "react";

import "./LearnMore.scss";

export const LearnMore = () => {
  return (
    <div className="info-text">
      <div>Info page</div>
    </div>
  );
};

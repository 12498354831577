import * as React from "react";
import { useEffect, useState } from "react";

import "../components/navBar/NavBar.scss";
import { useWeb3React } from "@web3-react/core";

import { BigNumber, ethers } from "ethers";

export interface UseFetchBalanceResponse {
  balance: string;
  update: () => Promise<BigNumber>;
  rawBalance: BigNumber;
}

export const useFetchAvaxBalance = (): UseFetchBalanceResponse => {
  const { account, provider, chainId } = useWeb3React();
  const [balance, setBalance] = useState<string>("0");
  const [rawBalance, setRawBalance] = useState<BigNumber>(BigNumber.from(0));

  async function updateAvaxBalance() {
    if (!provider || !account) return;
    const balance = await provider.getBalance(account);
    const balanceString = ethers.utils.formatEther(balance);
    // round the string to 4 decimal places
    const roundedBalance = parseFloat(balanceString).toString();
    setBalance(roundedBalance);
    setRawBalance(balance);
    return balance;
  }

  useEffect(() => {
    updateAvaxBalance();
  }, [provider, account, chainId]);

  return { balance, update: () => updateAvaxBalance(), rawBalance };
};

import { ERC20Config } from '../../environments/interface'
import * as React from 'react'
import { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { InfoRounded } from '@mui/icons-material'
import { Modal } from 'react-bootstrap'
import { Avatar } from '@mui/material'
import { BlockExplorerListItem } from './BlockExplorerListItem'
import { addTokenDetailsToMetaMask } from '../../web3/metamaskhelp'

export const TokenDetailsModal = ({ tokenDetails }: { tokenDetails: ERC20Config }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [showAddToMetaMask] = useState(true)
  const { connector } = useWeb3React()

  function toggleModal(force?: boolean) {
    return setIsModalOpen(force ?? !isModalOpen)
  }

  if (!tokenDetails) return <InfoRounded color={'disabled'} />

  return (
    <>
      <InfoRounded onClick={() => toggleModal()} color={'primary'} />
      <Modal
        className="connectModal"
        show={isModalOpen}
        onHide={() => toggleModal(false)}
        // onBackdropClick={() => toggleModal(false)}
        centered={true}
        size={'lg'}
        style={{ zIndex: 10000 }}
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant={'white'}>
          <Avatar style={{ borderRadius: '50%', marginInline: 6 }}>
            <img width={'100%'} height={'100%'} src={tokenDetails?.logo} alt={''} />
          </Avatar>{' '}
          <Modal.Title>Contract Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <BlockExplorerListItem config={tokenDetails} />
            <li>Symbol: {tokenDetails?.symbol}</li>
            <li>Decimals: {tokenDetails?.decimals}</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          {showAddToMetaMask && (
            <button
              hidden={tokenDetails?.symbol === 'AVAX'}
              disabled={!tokenDetails.address}
              className={'btn btn-primary'}
              onClick={() => addTokenDetailsToMetaMask(connector, tokenDetails)}
            >
              Add to MetaMask
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

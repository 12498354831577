import { config } from '../environments/environments'
import { ERC20Config } from '../environments/interface'
import { Connector } from '@web3-react/types'

const environment = process.env.NODE_ENV

export async function switchOrAddAvalancheNetworkToMetaMask(provider) {
  let chainId = 43114
  if (environment !== 'production') {
    chainId = 43113
  }
  let params = {
    chainId: `0x${chainId.toString(16)}`,
    chainName: config.networks[chainId].chainName,
    nativeCurrency: config.networks[chainId].nativeCurrency,
    rpcUrls: config.networks[chainId].rpcUrls,
    blockExplorerUrl: config.networks[chainId].blockExplorerUrl,
  }

  try {
    // attempt to switch to Harmony One network
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [params],
    })
  } catch (switchError) {
    console.log('switch error', switchError)
    // 4902 indicates that the client does not recognize the Harmony One network
    if (switchError.code === 4902) {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [params],
      })
    }
  }
}

export async function addTokenDetailsToMetaMask(
  connector: Connector,
  erc20: ERC20Config
) {
  connector
    .watchAsset({
      address: erc20.proxyAddress ?? erc20.address,
      symbol: erc20.symbol,
      decimals: erc20.decimals,
      image: erc20.logo,
    })
    .then((success) => {
      if (success) {
        console.log('FOO successfully added to wallet!')
      } else {
        throw new Error('Something went wrong.')
      }
    })
    .catch(console.error)
}

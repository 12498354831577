import { SupportedCryptoCurrency } from './interfaces/currencyInterfaces'
import {
  getLocaleForCurrencyLocale,
  KG_IN_GRAMS,
  MG_IN_GRAMS,
  OZT_IN_GRAMS,
  supportedCryptoCurrency,
} from './constants'
import { Token, WeightUnitStrings } from './interfaces/interfaces'
import { BigNumber, ethers } from 'ethers'

export const navigateToBrowse = (filter = []): string => {
  if (!filter || filter.length === 0) {
    return '/browse'
  }

  const filterString = filter.map((f) => `${Object.keys(f)[0]}=${Object.values(f)[0]}`).join('&')

  return `/browse?${filterString}`
}

export const formattedPrice = (
  balance: string | number | BigNumber,
  ppu, // price per unit. see https://members.lode.one/api/publicinfo -> agx_price
  currency
) => {
  if (!ppu || !currency || !balance) return undefined
  if (typeof balance === 'string') balance = parseFloat(balance)
  if (balance instanceof BigNumber) {
    balance = parseFloat(ethers.utils.formatUnits(balance, 18))
  }
  if (supportedCryptoCurrency.includes(currency as SupportedCryptoCurrency)) {
    return `${(balance * parseFloat(ppu)).toFixed(8)} ${currency}`
  }

  const formatter = new Intl.NumberFormat(
    getLocaleForCurrencyLocale(
      //@ts-ignore
      currency
    ),
    {
      style: 'currency',
      currency,
    }
  )

  return `${formatter.format(ppu * balance)} ${currency}`
}

// function that returns the price of a currency converted to USD

export const addressAsEllipses = (address: string, subStringAmt = 6) => {
  if (!address) return ''
  if (address.length <= subStringAmt) return address
  return address.substring(0, subStringAmt) + '...' + address.substring(address.length - subStringAmt)
}

export const displayTokensWeight = (
  token: Partial<Token>,
  options: {
    displayWeightUnit?: WeightUnitStrings
    decimals?: number
    baseUnit?: WeightUnitStrings
  } = { baseUnit: 'g' }
) => {
  if (!options.displayWeightUnit) {
    options.displayWeightUnit = token.metadata.displayWeightUnit
  }

  if (!options.decimals) {
    if (token.metadata.shape === 'bar') {
      options.decimals = 2
    }
    if (token.metadata.shape === 'coin') {
      options.decimals = 0
    }
  }

  // to float
  const weightInGrams = parseFloat(token.metadata.weightInGrams)
  return convertWeight(weightInGrams, options.baseUnit, options.displayWeightUnit, { decimals: options.decimals })
}

// function that can take ozt, g, kg and returns the weight in ozt, g, kg
export const convertWeight = (
  weight: number,
  from: WeightUnitStrings,
  to: WeightUnitStrings,
  options: {
    decimals?: number
  } = { decimals: 2 }
): any => {
  if (!from || !to) return undefined
  try {
    if (from === to) return weight.toFixed(options.decimals)

    let weightInGrams = 0

    switch (from) {
      case 'g':
        weightInGrams = weight
        break
      case 'kg':
        weightInGrams = weight * KG_IN_GRAMS
        break
      case 'ozt':
        weightInGrams = weight * OZT_IN_GRAMS
        break
      case 'mg':
        weightInGrams = weight * MG_IN_GRAMS
        break
      default:
        return weight
    }

    switch (to) {
      case 'g':
        return weightInGrams.toFixed(options.decimals)
      case 'kg':
        return (weightInGrams / KG_IN_GRAMS).toFixed(options.decimals)
      case 'ozt':
        return (weightInGrams / OZT_IN_GRAMS).toFixed(options.decimals)
      case 'mg':
        return (weightInGrams / MG_IN_GRAMS).toFixed(options.decimals)
      default:
        return weight
    }
  } catch (e) {
    return undefined
  }
}

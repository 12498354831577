import {
  CurrencyLocale,
  FiatCurrencyType,
  SupportedCryptoCurrency,
  SupportedCurrency,
  SupportedFiatCurrency,
} from "./interfaces/currencyInterfaces";

export const getLocaleForCurrencyLocale = (
  currency: FiatCurrencyType
): CurrencyLocale => {
  switch (currency) {
    case "USD":
      return "en-US";
    case "EUR":
      return "de-DE";
    case "GBP":
      return "en-GB";
    case "CAD":
      return "en-CA";
    case "AUD":
      return "en-AU";
    case "NZD":
      return "en-NZ";
    case "CHF":
      return "de-CH";
    case "JPY":
      return "ja-JP";
    case "CNY":
      return "zh-CN";
    case "RUB":
      return "ru-RU";
    case "INR":
      return "en-IN";
    case "BRL":
      return "pt-BR";
    case "MXN":
      return "es-MX";
    case "HKD":
      return "en-HK";
    case "TRY":
      return "tr-TR";
    case "NOK":
      return "nb-NO";
    case "ZAR":
      return "en-ZA";
    case "IDR":
      return "id-ID";
    case "KRW":
      return;
  }
};

// function to return the locales for a given currency
export const getLocaleForCurrency = (currency: string) => {
  switch (currency) {
    case "USD":
      return "en-US";
    case "EUR":
      return "de-DE";
    case "GBP":
      return "en-GB";
    case "CAD":
      return "en-CA";
    case "AUD":
      return "en-AU";
    case "NZD":
      return "en-NZ";
    case "CHF":
      return "de-CH";
    case "JPY":
      return "ja-JP";
    case "CNY":
      return "zh-CN";
    case "RUB":
      return "ru-RU";
    case "INR":
      return "en-IN";
    case "BRL":
      return "pt-BR";
    case "MXN":
      return "es-MX";
    case "HKD":
      return "en-HK";
    case "TRY":
      return "tr-TR";
    case "NOK":
      return "nb-NO";
    case "ZAR":
      return "en-ZA";
    case "IDR":
      return "id-ID";
    case "KRW":
      return "ko-KR";
    case "MYR":
      return "en-MY";
    case "PHP":
      return "en-PH";
    case "THB":
      return "th-TH";
    case "VND":
      return "vi-VN";
    case "PKR":
      return "en-PK";
    case "EGP":
      return "en-EG";
    case "SAR":
      return "ar-SA";
    case "KWD":
      return "ar-KW";
    case "OMR":
      return "ar-OM";
    case "QAR":
      return "ar-QA";
    case "AED":
      return "ar-AE";
    case "BHD":
      return "ar-BH";
    case "JOD":
      return "ar-JO";
    case "LYD":
      return "ar-LY";
  }
};

export const supportedCryptoCurrency: SupportedCryptoCurrency[] = [
  "ETH",
  "BTC",
  "LTC",
];
export const supportedFiatCurrency: SupportedFiatCurrency[] = [
  "USD",
  "CAD",
  "EUR",
  "MXN",
  "GBP",
  "JPY",
];

export const supportedCurrencies: SupportedCurrency[] = [
  ...supportedCryptoCurrency,
  ...supportedFiatCurrency,
];

export const supportedLanguages = ["en-US", "de-DE"];

export const OZT_IN_GRAMS = 31.1034768;
export const KG_IN_GRAMS = 1000;
export const MG_IN_GRAMS = 0.001;

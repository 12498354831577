import { ERC20Config, ERC721Config } from '../../environments/interface'
import { ListItem, ListItemText, Typography } from '@mui/material'
import { addressAsEllipses } from '../../functions'
import * as React from 'react'

/**
 * @param address
 */
function addressBlockExplorer({ config }: { config: ERC20Config | ERC721Config }) {
  return `${config.blockExplorer}/address/${config?.proxyAddress ?? config?.address}`
}

/**
 * @param erc20Contract
 */
export const BlockExplorerListItem = ({ config }: { config: ERC20Config | ERC721Config }) => {
  return (
    <ListItem sx={{ color: 'white' }}>
      <ListItemText
        primary={
          <Typography
            variant="h1"
            sx={{
              color: 'white',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body2" sx={{ color: 'white' }}>
              {config.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              <a target={'_blank'} rel="noreferrer" href={addressBlockExplorer({ config })}>
                {addressAsEllipses(config?.proxyAddress ?? config?.address, 10)}
              </a>
            </Typography>
          </Typography>
        }
      />
    </ListItem>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import './Vaultstatus.scss'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import Card from '@mui/material/Card'
import { NFTtabel } from './NFTtable'
import { convertWeight } from '../../functions'
import { CircularProgress, Typography } from '@mui/material'
import { ConfigContext } from '../../context/configProvider'
import { useTokenContract } from '../../hooks/useTokenContract'
import { u256ToNum } from '../purchase/txHelpers'

interface VaultCardContentParams {
  props: {
    to: 'ozt' | 'g' | 'kg'
    decimals: number
    weight1: number
    weight2: number
  }
}

function VaultCardContent({ props: { decimals, to, weight1, weight2 } }: VaultCardContentParams) {
  return (
    <Card className="vault-card">
      <div className="vault-card-content">
        <div className="content-1 mt-4">AGX Minted</div>
        <div className="content-2 mt-2">
          {convertWeight(weight1, 'ozt', to, {
            decimals,
          }) ?? <CircularProgress />}
          {to}
        </div>
        <hr />
        <div className="content-3 mt-1">Circulating Supply</div>
        <div className="content-4 mt-2">
          {convertWeight(weight2, 'ozt', to, {
            decimals,
          }) ?? <CircularProgress />}
          {to}
        </div>
      </div>
    </Card>
  )
}

type units = 'ozt' | 'g' | 'kg'

function ChangeUnit(props: { value: 'ozt' | 'g' | 'kg'; onChange: (e) => void }) {
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select value={props.value} onChange={props.onChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
        <MenuItem value="ozt">Troy Ounce</MenuItem>
        <MenuItem value="g">Gram</MenuItem>
        <MenuItem value="kg">Kilogram</MenuItem>
      </Select>
    </FormControl>
  )
}

export const VaultStatusPage = () => {
  const { networkConfig } = useContext(ConfigContext)
  const { agx, aux } = networkConfig?.tokens
  const { vaultConfig } = networkConfig?.contracts

  const agxC = useTokenContract(agx)
  const auxC = useTokenContract(aux)

  const [agxVaultBalance, setAgxVaultBalance] = useState(undefined)
  const [auxVaultBalance, setAuxVaultBalance] = useState(undefined)

  const updateVaultAuxBalance = async (): Promise<void> =>
    auxC.balanceOf(vaultConfig.proxyAddress).then((bal) => setAuxVaultBalance(u256ToNum(bal)))

  const updateVaultAgxBalance = async (): Promise<void> =>
    agxC.balanceOf(vaultConfig.proxyAddress).then((bal) => setAgxVaultBalance(u256ToNum(bal)))

  useEffect(() => {
    if (!agxC || !auxC) return
    updateVaultAuxBalance()
    updateVaultAgxBalance()
  }, [agxC, auxC])

  const [unit, setUnit] = useState<units>('ozt')
  const [decimals, setDecimals] = useState<number>(2)

  const handleUnitChange = (e) => {
    setUnit(e.target.value)
  }

  const handleDecimalChange = (e) => {
    setDecimals(Number(e.target.value))
  }

  return (
    <div className="vault-status">
      <div className="row mt-5">
        <div className="col-12 d-flex align-items-center">
          <Typography className="text-light">Unit</Typography>
          <ChangeUnit value={unit} onChange={handleUnitChange} />
          <Typography hidden className="text-light ms-2">
            Decimals
          </Typography>
          <FormControl hidden sx={{ m: 1, width: 60 }} size="small">
            <Select
              value={decimals}
              onChange={handleDecimalChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {/* vault cards */}
      <div className="row mt-2">
        <div className="col-6 col-md-3">
          <VaultCardContent
            props={{
              to: unit,
              weight1: agxVaultBalance,
              weight2: auxVaultBalance,
              decimals,
            }}
          />
        </div>{' '}
        <div className="col-6 col-md-3">
          <VaultCardContent
            props={{
              to: unit,
              weight1: 184951.27,
              weight2: 184951.27,
              decimals,
            }}
          />
        </div>{' '}
        <div className="col-6 col-md-3">
          <VaultCardContent
            props={{
              to: unit,
              weight1: 184951.27,
              weight2: 184951.27,
              decimals,
            }}
          />
        </div>{' '}
        <div className="col-6 col-md-3">
          <VaultCardContent
            props={{
              to: unit,
              weight1: 184951.27,
              weight2: 184951.27,
              decimals,
            }}
          />
        </div>
      </div>

      {/* vault table */}
      <div className="row mt-5">
        <div className="col-12 table-title mt-5">All LODE TRPs</div>
        <div className="col-12 table-tag ">Vault was last confirmed by Chainlink on 8/02/2022.</div>
        <div className="col-12 mt-3">
          <NFTtabel />
        </div>
      </div>
    </div>
  )
}

import React, { Context, createContext, useEffect, useReducer } from "react";
import { SupportedCurrency } from "./interfaces/currencyInterfaces";

export const CURRENCY: SupportedCurrency = "USD";

interface CurrencyContext {
  currency: SupportedCurrency;
  dispatch: React.Dispatch<any>;
}

const initialState =
  JSON.parse(localStorage.getItem("selectedCurrency") as string) || CURRENCY;

const currencyReducer = (_: any, currency: SupportedCurrency) => currency;
const CurrencyContext: Context<CurrencyContext> = createContext(
  {} as CurrencyContext
);

const CurrencyProvider = ({ children }) => {
  const [currency, dispatch] = useReducer(currencyReducer, initialState);

  useEffect(() => {
    localStorage.setItem("selectedCurrency", JSON.stringify(currency));
  }, [currency]);

  return (
    <CurrencyContext.Provider
      value={{
        currency: currency,
        dispatch,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export { CurrencyProvider, CurrencyContext };

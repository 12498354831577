import React, { useContext, useEffect, useState } from 'react'
import './NFTMintForm.scss'
import { useWeb3React } from '@web3-react/core'
import { Button } from 'react-bootstrap'
import { Token } from '../../interfaces/interfaces'
import { ConfigContext } from '../../context/configProvider'
import { AbiFunctions } from '../../context/contractTestingProvider'
import { getAllowance } from '../../pages/purchase/txHelpers'
import { getContract } from '../../hooks/useTokenContract'
import { useContract } from '../../hooks'
import { approve, hasRole, issue } from '../../web3'
import { BigNumber, Contract, ethers } from 'ethers'
import { Input, InputLabel, Paper, TextField } from '@mui/material'
import { TokenListContext } from '../../context/tokenListProvider'
import MenuItem from '@mui/material/MenuItem'
import { Check, Close } from '@mui/icons-material'
import { role } from '../../environments/environments'
import { ERC721Config } from '../../environments/interface'
import { WhiteListAdmin } from './WhiteListAdmin'

async function approveToken(
  tokenContract: Contract,
  vaultConfig: ERC721Config,
  tokenIdOrAmt: BigNumber | number
): Promise<void> {
  const approvalTx = await approve(tokenContract)(vaultConfig.proxyAddress ?? vaultConfig.address, tokenIdOrAmt)
  await approvalTx.wait(1)
}

export const NFTMintForm = () => {
  // get agx balance
  const { account, provider } = useWeb3React()

  const { mintedTokens } = useContext(TokenListContext)
  const { networkConfig } = useContext(ConfigContext)
  const { vaultConfig, marketPlaceConfig, nftConfig } = networkConfig.contracts

  const vaultContract = useContract(vaultConfig)
  const nftContract = useContract(nftConfig)

  // form to create a new nft using TokenMetaData
  const [formData, setFormData] = useState<any>()

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleIssue = async (input: Token | string): Promise<void> => {
    // issue from token id or token

    let token = input as Token
    if (typeof input === 'string') {
      token = mintedTokens.find((token) => {
        return token.tokenId === parseInt(input)
      })
    }

    if (!token) throw new Error('Token not found')

    const contract = getContract(token, provider?.getSigner())

    if (!contract) throw new Error('Token contract not found')
    if (!(contract instanceof Contract)) {
      throw new Error('Token contract instance not found')
    }
    const allowance = await getAllowance({
      tokenContract: contract,
      account: account,
      erc721Config: vaultConfig,
    })

    if (parseInt(allowance) < 100000) {
      await approveToken(contract, vaultConfig, ethers.constants.MaxUint256)
    }

    // if account has OWNER_ROLE then give no premium
    const isOwner = await hasRole(vaultContract)(role('OWNER_ROLE'), account)

    const issueTx = await issue(vaultContract)(
      token.tokenId,
      ethers.utils.parseEther(token.erc20Equivalence.toString()),
      isOwner ? 0 : ethers.utils.parseEther(token.premium.toString())
    )
    await issueTx.wait(1)
  }

  const handleMintToken = async () => {
    const tokenConfig = networkConfig.tokens[formData.mintToken]
    const tokenContract = getContract(tokenConfig, provider?.getSigner())
    const mintTx = await tokenContract.mint(
      formData.mintAddress,
      //number to wei
      ethers.utils.parseEther(formData.mintAmount)
    )
    await mintTx.wait(1)
  }

  const handleMintNFT = async () => {
    //get nftContract.mintingId
    const mintingId = await nftContract.mintingId()
    // ensure that the tokenURI ends with mintingId
    if (!formData.mintTokenURI.endsWith(mintingId)) {
      throw new Error('Token URI must end with mintingId')
    }

    const mintTx = await vaultContract.mint(
      formData.mintTokenAddress,
      ethers.utils.parseEther(formData.mintErc20Equivalence),
      formData.mintTokenURI,
      ethers.utils.parseEther(formData.mintPremium)
    )
    await mintTx.wait(1)
  }

  return (
    <div>
      <WhiteListAdmin />
      <Paper variant={'outlined'} style={{ padding: 30 }}>
        <h2>Issue Token</h2>
        <button onClick={() => handleIssue(formData.issueId)}>issue</button>
        <label htmlFor={'issueId'}>issue token by id</label>
        <input type={'number'} name={'issueId'} id={'issueId'} onChange={handleChange} />
      </Paper>

      <Paper variant={'outlined'} style={{ padding: 30 }}>
        <h2>Approve Vault to use nft</h2>
        <button
          disabled={!formData?.approveId}
          onClick={async () => await approveToken(nftContract, vaultConfig, formData?.approveId)}
        >
          approve token id
        </button>
        <label htmlFor={'approveId'}>approve token by id</label>
        <input type={'number'} name={'approveId'} id={'approveId'} onChange={handleChange} />
      </Paper>

      <MintErcTokenForm formData={formData} handleMintToken={handleMintToken} handleChange={handleChange} />

      <MintNFTForm formData={formData} handleMintToken={handleMintNFT} handleChange={handleChange} />
      <AbiFunctions contractConfig={networkConfig.contracts.vaultConfig} />
      <AbiFunctions contractConfig={networkConfig.contracts.nftConfig} />
    </div>
  )
}
const isAddressValid = (address) => {
  try {
    ethers.utils.getAddress(address)
    return true
  } catch (e) {
    return false
  }
}

function MintErcTokenForm({ formData, handleChange, handleMintToken }) {
  return (
    <Paper variant={'outlined'} style={{ padding: 30, marginBlock: 10 }}>
      <h2>Mint ERC20 Token</h2>
      <p>Select Token to mint: {formData?.mintToken}</p>
      <p>Amount: {formData?.mintAmount} </p>
      <p>
        To Address: {formData?.mintAddress} {isAddressValid(formData?.mintAddress) ? <Check /> : <Close />}
      </p>
      <hr />
      <TextField
        id="outlined-select-currency"
        select
        label="Select"
        name="mintToken"
        value={formData?.mintToken}
        onChange={handleChange}
        helperText="a token to mint"
      >
        {erc20MintConfig.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <br />
      <InputLabel htmlFor={'mintAddress'}>mint token to address</InputLabel>
      <Input
        error={!isAddressValid(formData?.mintAddress)}
        multiline
        type={'text'}
        name={'mintAddress'}
        id={'mintAddress'}
        value={formData?.mintAddress}
        onChange={handleChange}
      />
      <br />
      <InputLabel htmlFor={'mintAmount'}>mint amount</InputLabel>
      <Input type={'number'} name={'mintAmount'} id={'mintAmount'} onChange={handleChange} />{' '}
      <Button
        variant={'primary'}
        color={'secondary'}
        className={'ms-5'}
        disabled={
          !formData?.mintToken ||
          !formData?.mintAddress ||
          !formData?.mintAmount ||
          !isAddressValid(formData?.mintAddress)
        }
        onClick={() => handleMintToken()}
      >
        mint erc20
      </Button>
    </Paper>
  )
}

function MintNFTForm({ formData, handleChange, handleMintToken }) {
  return (
    <Paper variant={'outlined'} style={{ padding: 30, marginBlock: 10 }}>
      <h2>Mint NFT</h2>
      <p>Token Address: {formData?.mintTokenAddress} </p>
      <p>ERC20 Equivalence: {formData?.mintErc20Equivalence} </p>
      <p>Token URI: {formData?.mintTokenURI} </p>
      <p>Premium: {formData?.mintPremium} </p>
      <hr />
      <TextField
        id="outlined-select-currency"
        select
        label="Select"
        name="mintTokenAddress"
        value={formData?.mintTokenAddress}
        onChange={handleChange}
        helperText="a token to mint"
      >
        {erc20MintConfig.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <br />
      <InputLabel htmlFor={'mintErc20Equivalence'}>Erc20 Equivalence</InputLabel>
      <Input
        fullWidth
        type={'number'}
        name={'mintErc20Equivalence'}
        id={'mintErc20Equivalence'}
        onChange={handleChange}
      />{' '}
      <br />
      <InputLabel htmlFor={'mintTokenURI'}>Token URI</InputLabel>
      <Input
        type={'textarea'}
        fullWidth
        rows={5}
        name={'mintTokenURI'}
        id={'mintTokenURI'}
        defaultValue={'https://633b339f471b8c39557e75f8.mockapi.io/metadata/'}
        onChange={handleChange}
      />
      <br />
      <InputLabel htmlFor={'mintPremium'}>Premium</InputLabel>
      <Input fullWidth type={'number'} name={'mintPremium'} id={'mintPremium'} onChange={handleChange} />{' '}
      <Button
        variant={'primary'}
        color={'secondary'}
        className={'ms-5'}
        disabled={
          !formData?.mintTokenAddress ||
          !formData?.mintErc20Equivalence ||
          !formData?.mintTokenURI ||
          !formData?.mintPremium
        }
        onClick={() => handleMintToken()}
      >
        mint NFT
      </Button>
    </Paper>
  )
}

const erc20MintConfig = [
  {
    value: '0xf3ce5a9280f379fF14D14EA838Af478073825b3A',
    label: 'AGX',
  },
  {
    value: '0x6864Bb5Fdc697477cb76bd8135077DE18cde1f3d',
    label: 'AUX',
  },
  { value: '0x2D1b68D22039f26322679302de918a130929436F', label: 'LODE' },
]

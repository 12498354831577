import React, { useContext, useState } from 'react'
import './PurchaseHome.scss'

import { SpotLightCard } from '../../home/spotlight/SpotLightCard'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { Token } from '../../../interfaces/interfaces'
import { PurchasePageButton } from '../purchaseItem/PurchasePageButton'
import { TokenListContext } from '../../../context/tokenListProvider'
import _ from 'lodash'
export const PurchaseHome = () => {
  const navigate = useNavigate()
  const { mintedTokens } = useContext(TokenListContext)

  // group minted tokens by their weights (erc20Equivalence) and contract types (agx, aux)
  const groupedMintedTokens = mintedTokens.reduce((acc, token) => {
    const { erc20Equivalence, tokenConfig } = token
    const key = `${erc20Equivalence}-${tokenConfig.symbol}`
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(token)
    return acc
  }, {})

  // take one token from each group and use it to display the purchase page
  const purchasePageTokens = Object.values(groupedMintedTokens).map((tokens: Token[]) => ({
    tokens: tokens,
    quantity: tokens.length,
  }))

  return (
    <div className="purchase-home">
      <div className="back-navigation">
        <PurchasePageButton
          isLoading={false}
          onClick={() => navigate('/')}
          startIcon={<ArrowBack />}
          variant={'outlined'}
          size={'medium'}
          className={'backButton'}
          text="Back"
        />
      </div>
      <h1 className={'primary-text'}>
        <div className={'explore-and-collect'}>
          Purchase <span className={'silver-text'}>Silver</span>
        </div>

        <div className={'silver-and-gold'}>
          and <div className={'gold-text'}>Gold</div> TRPs
        </div>
      </h1>
      {/*  go back to home */}
      <div className="spotlight-cards-container">
        {purchasePageTokens.map((tokens, key) => (
          <SpotLightCard
            key={key}
            tokens={tokens.tokens}
          />
        ))}
      </div>
    </div>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import '../../App.scss'
import { useWeb3React } from '@web3-react/core'

import './ConnectModal.scss'
import '../../pages/home/Home.scss'
import { Modal } from 'react-bootstrap'
import { Login, Logout } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useLocalStorage } from '../../hooks'

import { WalletConnect } from '@web3-react/walletconnect'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'

import { metaMask } from '../../web3/connectors/metaMask'
import { walletConnect } from '../../web3/connectors/walletConnect'

import type { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import { ConfigContext } from '../../context/configProvider'
import { useToastDispatch } from '../../context/toastContext'

const metaMaskIcon = require('../../resources/img/metamask.png')
const walletConnectIcon = require('../../resources/img/walletconnect.png')

export const ConnectorButton = ({
  label,
  image = '',
  disabled = false,
}: {
  label: string
  image: string
  disabled?: boolean
}) => {
  return (
    <div>
      <Button disabled={disabled}>
        {image && <img src={image} alt={label} className="connect-btn-img" />}
        {/*  <div>{label} </div> */}
      </Button>
      <div className="button-label">{label}</div>
    </div>
  )
}

function ConnectModal({
  showModalOverride = undefined,
  setShowModalOverride = undefined,
}: {
  showModalOverride?: boolean | undefined
  setShowModalOverride?: (boolean) => void | undefined
}) {
  const { isActive, connector, isActivating } = useWeb3React()
  const [showModal, setShowModal] = useState(false)

  const { desiredChainId } = useContext(ConfigContext)
  const { showToast } = useToastDispatch()

  const [localStorageProvider, setLocalStorageProvider] = useLocalStorage('provider', '')

  useEffect(() => {
    showToast({
      message: 'woo',
      type: 'danger',
      position: 'top-right',
      duration: 5000,
    })
    if (showModalOverride === undefined) setShowModal(false)
  }, [isActive])

  useEffect(() => {
    if (showModalOverride === undefined && !isActivating && !isActive) setShowModal(true)
  }, [])

  const handleClose = () => {
    if (setShowModalOverride) {
      return setShowModalOverride(false)
    }
    setShowModal(false)
  }

  function handleConnectErrorMessage(error) {}

  async function handleConnect(
    connectInstance: CoinbaseWallet | Network | MetaMask | WalletConnect,
    desiredChainId: number,
    setError: (error: Error | undefined) => void
  ) {
    try {
      await connector.resetState() // get instance type
      const instanceType = connectInstance.constructor.name
      // set local storage provider
      setLocalStorageProvider(instanceType)

      try {
        let activate = await connectInstance?.activate(desiredChainId).then((a) => console.log(a))
      } catch (error) {
        handleConnectErrorMessage(error)
      }

      // console.log(connectInstance, provider, connector, activate, accounts);
    } catch (error) {
      setLocalStorageProvider('')
      setError(error)
    }
  }

  return (
    <>
      <Modal
        show={showModalOverride !== undefined ? showModalOverride : showModal}
        onHide={handleClose}
        centered
        className="connectModal"
      >
        <>
          <div className="connect-header">
            <div className="title">
              <div>Connect Wallet</div>
            </div>
            <button
              type="button"
              className="btn-close btn-close-white close-button"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="content-header">Choose one of the eligible wallets below</div>
          <Modal.Body className={'connectModalBody'}>
            <Button
              className="connector-button-wrapper connector-button"
              onClick={async () => {
                await handleConnect(walletConnect, desiredChainId, (error: Error) => handleConnectErrorMessage(error))
                handleClose()
              }}
            >
              <ConnectorButton image={walletConnectIcon} label={'Wallet Connect'} />
            </Button>
            <Button
              className="connector-button-wrapper connector-button small-img"
              onClick={async () => {
                await handleConnect(metaMask, desiredChainId, (error: Error) => handleConnectErrorMessage(error))
                handleClose()
              }}
            >
              <ConnectorButton image={metaMaskIcon} label={'Metamask'} />
            </Button>
          </Modal.Body>
        </>
      </Modal>
    </>
  )
}

export default ConnectModal

export const ConnectModalButton = () => {
  const { isActive, connector } = useWeb3React()

  const [connectModalShow, setConnectModalShow] = useState(false)
  const [localStorageProvider, setLocalStorageProvider] = useLocalStorage('provider', '')
  const handleDeactivate = async () => {
    setLocalStorageProvider('')
    if (connector?.deactivate) {
      connector?.deactivate()
    } else {
      connector?.resetState()
    }
    window.location.reload()
  }

  return (
    <>
      <div className="connect-button-wrapper">
        <button
          draggable={false}
          onClick={async () => {
            await (isActive ? handleDeactivate() : setConnectModalShow(true))
          }}
          className={`connect-button ${isActive ? 'active' : ''}`}
        >
          <div className={'connect-button-content'}>
            {!isActive ? <Login /> : <Logout />}
            {!isActive ? 'Connect Wallet' : 'Disconnect Wallet'}
          </div>
        </button>
      </div>
      <ConnectModal showModalOverride={connectModalShow} setShowModalOverride={setConnectModalShow} />
    </>
  )
}

import * as React from "react";
import { useEffect, useState } from "react";

import { useWeb3React } from "@web3-react/core";
import { useContract } from "./useContract";
import { ERC20Config, ERC721Config } from "../environments/interface";

export const useGetRoles = (contract_info: ERC20Config | ERC721Config) => {
  const { account } = useWeb3React();
  const contract = useContract(contract_info);
  const [access, setAccess] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    async function fetchRolesForContract() {
      // init roles
      const temp_roles = {};
      // if wallet is connected, and roles are specified in config
      if (account && contract_info.roles) {
        for (const [roleName, roleKeccak256] of Object.entries(
          contract_info.roles
        )) {
          temp_roles[roleName] = await contract.hasRole(roleKeccak256, account);
        }
        setAccess(temp_roles);
      }
    }

    fetchRolesForContract();
  }, [account, contract]);

  if (access) {
    return access;
  }

  return undefined;
};

import React, { useContext, useEffect, useState } from 'react'
import '../../App.scss'
import { useWeb3React } from '@web3-react/core'

import './ConnectModal.scss'
import '../../pages/home/Home.scss'
import { Login, Logout } from '@mui/icons-material'
import { Button, Card, CardContent } from '@mui/material'
import { useLocalStorage } from '../../hooks'

import { WalletConnect } from '@web3-react/walletconnect'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'

import { metaMask } from '../../web3/connectors/metaMask'
import { walletConnect } from '../../web3/connectors/walletConnect'

import type { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import { ConfigContext } from '../../context/configProvider'
import { useToastDispatch } from '../../context/toastContext'
import {ConnectorButton} from "./ConnectModal";

const metaMaskIcon = require('../../resources/img/metamask.png')
const walletConnectIcon = require('../../resources/img/walletconnect.png')



function Connect() {
  const web3 = useWeb3React()
  const { isActive, connector, provider, accounts, hooks } = web3

  const { desiredChainId } = useContext(ConfigContext)
  const { showToast } = useToastDispatch()

  const [localStorageProvider, setLocalStorageProvider] = useLocalStorage('provider', '')

  useEffect(() => {
    showToast({
      message: 'woo',
      type: 'danger',
      position: 'top-right',
      duration: 5000,
    })
  }, [isActive])




  async function handleConnect(
    connectInstance: CoinbaseWallet | Network | MetaMask | WalletConnect,
    desiredChainId: number,
    setError: (error: Error | undefined) => void
  ) {
    try {
      await connector.resetState() // get instance type
      const instanceType = connectInstance.constructor.name
      // set local storage provider
      setLocalStorageProvider(instanceType)

      try {

        let activate = await connectInstance?.activate(desiredChainId).then((a) => console.log(a))

      } catch (error) {

      }

      // console.log(connectInstance, provider, connector, activate, accounts);
    } catch (error) {
      setLocalStorageProvider('')
      console.log(error)
      setError(error)
    }
  }

  return (
    <>
      <Card className="connectModal bg-dark align-content-center">
        <div className="connect-header">
          <div className="title">
            <div>Connect Wallet</div>
          </div>
          <button
            type="button"
            className="btn-close btn-close-white close-button"
            aria-label="Close"
          ></button>
        </div>
        <div className="content-header">Choose one of the eligible wallets below</div>
        <CardContent className={'connectModalBody'}>
          <Button
            className="connector-button-wrapper connector-button"
            onClick={async () => {
              await handleConnect(walletConnect, desiredChainId, (error: Error) => {})
            }}
          >
            <ConnectorButton image={walletConnectIcon} label={'Wallet Connect'} />
          </Button>
          <Button
            className="connector-button-wrapper connector-button small-img"
            onClick={async () => {
              await handleConnect(metaMask, desiredChainId, (error: Error) => {})
            }}
          >
            <ConnectorButton image={metaMaskIcon} label={'Metamask'} />
          </Button>
        </CardContent>
      </Card>
    </>
  )
}

export default Connect

export const ConnectModalButton = () => {
  const { isActive, connector } = useWeb3React()

  const [connectModalShow, setConnectModalShow] = useState(false)
  const [localStorageProvider, setLocalStorageProvider] = useLocalStorage('provider', '')
  const handleDeactivate = async () => {
    setLocalStorageProvider('')
    if (connector?.deactivate) {
      connector?.deactivate()
    } else {
      connector?.resetState()
    }
    window.location.reload()
  }

  return (
    <>
      <div className="connect-button-wrapper">
        <button
          draggable={false}
          onClick={async () => {
            await (isActive ? handleDeactivate() : setConnectModalShow(true))
          }}
          className={`connect-button ${isActive ? 'active' : ''}`}
        >
          <div className={'connect-button-content'}>
            {!isActive ? <Login /> : <Logout />}
            {!isActive ? 'Connect Wallet' : 'Disconnect Wallet'}
          </div>
        </button>
      </div>
      {connectModalShow && (
        <Connect />
      )}
    </>
  )
}

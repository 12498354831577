import "./PageNotFound.scss";
import { Button } from "@mui/material";
import {Link} from "react-router-dom";
export function PageNotFoundView() {
  return (
    <div className={"page-not-found"}>
      <div className={"page-not-found__content"}>
        <div className={"page-not-found__content__title"}>404</div>
        <div className={"page-not-found__content__subtitle"}>
          Page not found
        </div>
        <div className={"page-not-found__content__description"}>
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </div>
      </div>
      <Button className={"page-not-found__button"} variant={"contained"} component={Link} to={'/'}>
        Go to home page
      </Button>
    </div>
  );
}

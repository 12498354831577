import * as React from "react";
import { useContext, useState } from "react";
import { useContract } from "../hooks";
import { ConfigContext } from "./configProvider";
import { useWeb3React } from "@web3-react/core";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { getRoleName, role } from "../environments/environments";
import { Contract, ethers } from "ethers";
import _ from "lodash";
import { ERC721Config } from "../environments/interface";
import { Grid, Paper } from "@mui/material";
import { grantRoleExecution, revokeRoleExecution } from "../web3";
import { useToastDispatch } from "./toastContext";

export type ContractTestContextType = {};

export const ContractTestProvider =
  React.createContext<ContractTestContextType | null>(null);
function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <Card.Header
      style={{
        backgroundColor: "aliceblue",
        position: "sticky",
        fontSize: "20px",
        padding: "10px",
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </Card.Header>
  );
}

function handleFunctionCall(
  account: string,
  formData: object,
  contract: Contract,
  setFunctionResults: (value: ((prevState: {}) => {}) | {}) => void,
  showToast: any
) {
  return async (name, inputs) => {
    if (!account) {
      console.log("no account");
      return;
    }

    const data = inputs.map((input) => {
      let unchangedData = formData[input];
      let { datatype, value } = unchangedData;

      if (datatype === "address") {
        return value;
      }
      if (datatype === "address[]") {
        return value?.split(",");
      }
      if (datatype === "uint256") {
        // convert to uint256 bignumber
        return ethers.BigNumber.from(value);
      }

      if (datatype === "bool") {
        return !!value;
      }

      if (datatype === "string") {
        return value;
      }

      if (datatype === "bytes32") {
        return role(value);
      }
      if (datatype === "uint256[]") {
        return value.split(",");
      }
    });
    let output;
    try {
      output = await contract[name](...data);
    } catch (e) {
      showToast({
        message: e.message,
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    }

    // if output is big number, convert to string
    if (ethers.BigNumber.isBigNumber(output)) {
      output = output.toString();
    }

    setFunctionResults((prev) => {
      return { ...prev, [name]: output };
    });
  };
}

export const AbiFunctions = ({ contractConfig }) => {
  const { account } = useWeb3React();
  const contract = useContract(contractConfig);
  const { networkConfig } = useContext(ConfigContext);

  const functionNames = ["mint", "issue", "mintingId"];
  const abiFunctions = contractConfig.abi.filter(
    (abi) =>
      abi.type === "function" &&
      (functionNames.includes(abi.name) ||
        abi.name.includes("burn") ||
        abi.name.includes("grant") ||
        abi.name.includes("redeem") ||
        abi.name.includes("owner"))
  );

  // make array to hold the result of each function call
  const [functionResults, setFunctionResults] = useState({});
  const [formData, setFormData] = useState<object>();
  const [roleProposals, setRoleProposals] = useState([]);
  const { showToast } = useToastDispatch();

  const getRoleProposals = async () => {
    // input is uint256 number
    let checkProposalUpTo = _.fill(Array(10), 0);

    let roleProposalPromises = await Promise.all(
      checkProposalUpTo.map(async (proposal, index) => {
        try {
          return await contract?.roleProposals(index);
        } catch (e) {
          return Promise.resolve();
        }
      })
    );
    roleProposalPromises = roleProposalPromises.filter((proposal) => {
      return proposal[0] !== ethers.constants.AddressZero;
    });

    setRoleProposals(roleProposalPromises);

    // const roleProposals = await contract.getRoleProposals();
    // setRoleProposals(roleProposals);
  };
  // dynamic function call based on abi
  const dynamicFunctionCall = handleFunctionCall(
    account,
    formData,
    contract,
    setFunctionResults,
    showToast
  );

  const handleInput = (e) => {
    const { name, value } = e.target;
    // get datatype attribute from input
    let datatype = e.target.getAttribute("datatype");

    setFormData({ ...formData, [name]: { value, datatype } });
  };
  const handleExecution = async (proposalIndex) => {
    try {
      await grantRoleExecution(contract)(proposalIndex);
    } catch (e) {
      showToast({
        message: e.message,
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    }
  };

  const handleRevokeExecution = async (proposalIndex) => {
    const output = await revokeRoleExecution(contract)(proposalIndex);
    console.log("output", output);
  };

  return (
    <Grid
      spacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      display={"flex"}
      flexWrap={"wrap"}
      justifyContent={"space-between"}
      direction={"row"}
    >
      <Paper>
        <h2>Role Proposals</h2>
        <button onClick={getRoleProposals}>Get Role Proposals</button>
        <div>
          {roleProposals.map((roleProposal, index) => (
            <div key={index}>
              <div>Role: {getRoleName(roleProposal.role)}</div>
              <div>Address: {roleProposal.account}</div>
              <div>
                timestamp Type:{" "}
                {new Date(
                  roleProposal.timestamp.mul(1000).toNumber()
                ).toDateString()}
              </div>
              <div>
                executed Status: {roleProposal.executed ? "yes" : "no"}
                <button onClick={() => handleExecution(index)}>
                  Execute Role Proposal
                </button>
                <button onClick={() => handleRevokeExecution(index)}>
                  Cancel Role Proposal
                </button>
              </div>
              <div>granted: {roleProposal.grant ? "yes" : "no"}</div>
            </div>
          ))}
        </div>
      </Paper>

      {abiFunctions.map((abi, index) => (
        <Grid item key={index} sm={3}>
          <Card key={index} style={{ width: "18rem", background: "#616161" }}>
            <Card.Header>
              <Card.Title>{abi.name}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {abi.type}
              </Card.Subtitle>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                {abi.inputs.map((input, index) => (
                  <div key={index}>
                    <div>{input.name}</div>
                    <label>{input.type}</label>
                    {input.type === "address" &&
                      (input.name === "token" ? (
                        <textarea
                          datatype={input.type}
                          onChange={handleInput}
                          defaultValue={networkConfig.tokens.agx.proxyAddress}
                          name={input.name}
                          placeholder={input.name}
                        />
                      ) : (
                        <textarea
                          datatype={input.type}
                          onChange={handleInput}
                          defaultValue={account}
                          name={input.name}
                          placeholder={input.name}
                        />
                      ))}
                    {input.type === "uint256" && (
                      <input
                        datatype={input.type}
                        onChange={handleInput}
                        type="number"
                        name={input.name}
                        placeholder={input.name}
                      />
                    )}
                    {input.type === "uint256[]" && (
                      <input
                        onChange={handleInput}
                        datatype={input.type}
                        type="number"
                        name={input.name}
                        placeholder={input.name}
                      />
                    )}
                    {input.type === "bool" && (
                      <input
                        datatype={input.type}
                        onChange={handleInput}
                        type="checkbox"
                        name={input.name}
                        placeholder={input.name}
                      />
                    )}

                    {input.type === "string" &&
                      (input.name === "tokenURI" ? (
                        <textarea
                          datatype={input.type}
                          onChange={handleInput}
                          defaultValue={
                            "https://633b339f471b8c39557e75f8.mockapi.io/metadata/"
                          }
                          name={input.name}
                          placeholder={input.name}
                        />
                      ) : (
                        <textarea
                          datatype={input.type}
                          onChange={handleInput}
                          name={input.name}
                          placeholder={input.name}
                        />
                      ))}
                    {input.type === "bytes32" && (
                      <textarea
                        datatype={input.type}
                        onChange={handleInput}
                        name={input.name}
                        placeholder={input.name}
                      />
                    )}

                    {input.type === "tuple[]" && (
                      <div>
                        <textarea
                          datatype={input.type}
                          onChange={handleInput}
                          name={input.name}
                          placeholder={input.name}
                        />
                      </div>
                    )}
                    <br />
                  </div>
                ))}
                <button
                  onClick={() =>
                    dynamicFunctionCall(
                      abi.name,
                      abi.inputs.map((input) => input.name)
                    )
                  }
                >
                  {abi.name}
                </button>
              </Card.Text>
              <fieldset>
                <legend>Output</legend>
                {abi.outputs.map((output, index) => (
                  <div key={index}>
                    <div>{output.name}</div>
                    <div>{output.type}</div>
                  </div>
                ))}
              </fieldset>

              {/* text area to show results */}
              <textarea
                value={functionResults[abi.name]}
                style={{ width: "100%", height: "100px" }}
              />
            </Card.Body>
          </Card>
        </Grid>
      ))}

      {/*  role proposals*/}
    </Grid>
  );
};

export function ContractAccordion({
  contract,
  label,
  eventKey,
}: {
  contract: ERC721Config;
  label: string;
  eventKey: string;
}) {
  return (
    <Accordion>
      <Accordion.Item
        eventKey={eventKey}
        style={{ maxHeight: "100vh", overflowY: "scroll" }}
      >
        <CustomToggle eventKey={eventKey}>{label}</CustomToggle>
        <Accordion.Body>
          <AbiFunctions contractConfig={contract} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export function ContractTests() {
  const { networkConfig } = useContext(ConfigContext);
  const { marketPlaceConfig, nftConfig, vaultConfig } = networkConfig.contracts;

  return (
    <>
      <ContractAccordion
        eventKey={"0"}
        label={"NFT Contract Functions"}
        contract={nftConfig}
      />
      <ContractAccordion
        eventKey={"1"}
        label={"Marketplace Contract Functions"}
        contract={marketPlaceConfig}
      />
      <ContractAccordion
        eventKey={"2"}
        label={"Vault Contract Functions"}
        contract={vaultConfig}
      />
    </>
  );
}

const ContractTestingProvider = ({ children }) => {
  return (
    <ContractTestProvider.Provider value={""}>
      {/*<Nav style={{ position: "fixed", zIndex: 10000 }}>*/}
      {/*  <ContractTests />*/}
      {/*</Nav>*/}
      {children}
    </ContractTestProvider.Provider>
  );
};

export default ContractTestingProvider;

import agxAbi from "../data/abi/AGX_ABI.json";
import auxAbi from "../data/abi/AUX_ABI.json";
import lodeAbi from "../data/abi/LODE_ABI.json";
import vaultAbi from "../data/abi/VAULT_ABI.json";
import nftAbi from "../data/abi/NFT_ABI.json";
import { ethers, utils } from "ethers";
import marketplaceAbi from "../data/abi/MARKETPLACE_ABI.json";
import { Config } from "./interface";
import avaxLogo from "../resources/img/avax-logo.png";

export function role(str) {
  return utils.keccak256(utils.toUtf8Bytes(str));
}

export function byte4(str) {
  return utils.keccak256(utils.toUtf8Bytes(str)).substring(0, 10);
}

export const getRoleName = (roleHash) => {
  switch (roleHash) {
    case role("OWNER_ROLE"):
      return "OWNER_ROLE";
    case role("MINTER_ROLE"):
      return "MINTER_ROLE";
    case role("BURNER_ROLE"):
      return "BURNER_ROLE";
    default:
      return false;
  }
};

// @ts-ignore
const fujiAgx = {
  name: "AGX",
  address: "0x8a9720e73a917997f0eb69e85fa23f53bce7ea39",
  proxyAddress: "0xf3ce5a9280f379fF14D14EA838Af478073825b3A",
  symbol: "AGX",
  abi: agxAbi,
  logo: "https://lode.one/wp-content/uploads/2021/12/AGX-Coin.png",
  decimals: 18,
  blockExplorer: "https://testnet.snowtrace.io/",
};
const fujiAux = {
  name: "AUX",
  address: "0x8a9720e73a917997f0eb69e85fa23f53bce7ea39",
  proxyAddress: "0x6864Bb5Fdc697477cb76bd8135077DE18cde1f3d",
  symbol: "AUX",
  abi: auxAbi,
  logo: "https://lode.one/wp-content/uploads/2021/12/AUX-Coin.png",
  decimals: 18,
  blockExplorer: "https://testnet.snowtrace.io/",
};
const fujiLode = {
  name: "LODE",
  address: "0x23eafedb9b7f5e3905f70a5cf608d5188a0144a2",
  proxyAddress: "0x2D1b68D22039f26322679302de918a130929436F",
  symbol: "LODE",
  abi: lodeAbi,
  logo: "https://lode.one/wp-content/uploads/2021/12/LODE-Token-1.png",
  decimals: 18,
  blockExplorer: "https://testnet.snowtrace.io/",
};
const fujiNFTContract = {
  name: "NFT",
  address: "0x5AceC082fd8d159a1456c377e1AE770D80b1359a",
  proxyAddress: "0x820270A34832208438b978643eBB1b7BF779285c",
  ethersInterface: new ethers.utils.Interface(nftAbi),
  abi: nftAbi,
  roles: {
    OWNER_ROLE: role("OWNER_ROLE"),
    MINTER_ROLE: role("MINTER_ROLE"),
    BURNER_ROLE: role("BURNER_ROLE"),
  },
  blockExplorer: "https://testnet.snowtrace.io/",
};
const fujiVaultContract = {
  name: "Vault",
  address: "0x5AceC082fd8d159a1456c377e1AE770D80b1359a",
  proxyAddress: "0x8c4E000ACE5e35A9EBa499577475Dc65122E1662",
  ethersInterface: new ethers.utils.Interface(vaultAbi),
  abi: vaultAbi,
  roles: {
    OWNER_ROLE: role("OWNER_ROLE"),
    MINTER_ROLE: role("MINTER_ROLE"),
    BURNER_ROLE: role("BURNER_ROLE"),
  },
  blockExplorer: "https://testnet.snowtrace.io/",
};
const fujiMarketPlaceContract = {
  address: "0x673Ab1Bb3663588bD71403Bd5dC45ac442Ae4f2A",
  blockExplorer: "https://testnet.snowtrace.io/",
  name: "BullPen",
  ethersInterface: new ethers.utils.Interface(marketplaceAbi),
  abi: marketplaceAbi,
  roles: {
    OWNER_ROLE: role("OWNER_ROLE"),
  },
};
export const config: Config = {
  // desiredChainId: process.env.NODE_ENV === "production" ? 43114 : 43113,
  desiredChainId: 43113,
  networks: {
    43114: {
      chainId: "0xA86A",
      chainName: "Avalanche Mainnet C-Chain",
      nativeCurrency: {
        name: "Avalanche",
        symbol: "AVAX",
        decimals: 18,
      },
      rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
      blockExplorerUrl: "https://snowtrace.io/",

      contracts: {
        marketPlaceConfig: {
          name: "BullPen",
          address: "0xB967502b72C8436d8F05B9c8D302f79b9BDda5Af",
          abi: marketplaceAbi,
          roles: {
            OWNER_ROLE: role("OWNER_ROLE"),
            MINTER_ROLE: role("LISTER_ROLE"),
          },
          blockExplorer: "https://snowtrace.io/",
        },
        vaultConfig: {
          name: "Vault",
          address: "0x420B712a992915baa1Fd99dDdB13Aa217C50dDe9",
          abi: vaultAbi,
          roles: {
            OWNER_ROLE: role("OWNER_ROLE"),
            MINTER_ROLE: role("MINTER_ROLE"),
            BURNER_ROLE: role("BURNER_ROLE"),
          },
          blockExplorer: "https://snowtrace.io/",
        },
        nftConfig: {
          name: "NFT",
          address: "0x420B712a992915baa1Fd99dDdB13Aa217C50dDe9",
          abi: vaultAbi,
          roles: {
            OWNER_ROLE: role("OWNER_ROLE"),
            MINTER_ROLE: role("MINTER_ROLE"),
            BURNER_ROLE: role("BURNER_ROLE"),
          },
          blockExplorer: "https://snowtrace.io/",
        },
      },
      tokens: {
        agx: {
          name: "AGX",
          address: "0x13E7bceFddE72492E656f3fa58baE6029708e673",
          symbol: "AGX",
          abi: agxAbi,
          logo: "https://lode.one/wp-content/uploads/2021/12/AGX-Coin.png",
          decimals: 18,
          blockExplorer: "https://snowtrace.io/",
        },
        aux: {
          name: "AUX",
          address: "0x68327a91E79f87F501bC8522fc333FB7A72393cb",
          symbol: "AUX",
          abi: auxAbi,
          logo: "https://lode.one/wp-content/uploads/2021/12/AUX-Coin.png",
          decimals: 18,
          blockExplorer: "https://snowtrace.io/",
        },
        lode: {
          name: "LODE",
          address: "0xbBAAA0420D474B34Be197f95A323C2fF3829E811",
          symbol: "LODE",
          abi: lodeAbi,
          logo: "https://lode.one/wp-content/uploads/2021/12/LODE-Token-1.png",
          decimals: 18,
          blockExplorer: "https://snowtrace.io/",
        },
        avax: {
          name: "Avalanche",
          decimals: 18,
          logo: avaxLogo,
          symbol: "AVAX",
          blockExplorer: "https://snowtrace.io/",
        },
      },
    },

    43113: {
      chainId: "0xA869",
      chainName: "Avalanche Testnet C-Chain",
      nativeCurrency: {
        name: "Avalanche",
        symbol: "AVAX",
        decimals: 18,
      },
      rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
      blockExplorerUrl: "https://testnet.snowtrace.io/",

      contracts: {
        //marketplace
        marketPlaceConfig: fujiMarketPlaceContract,
        [fujiMarketPlaceContract.address]: fujiMarketPlaceContract,

        nftConfig: fujiNFTContract,
        [fujiNFTContract.proxyAddress]: fujiNFTContract,

        vaultConfig: fujiVaultContract,
        [fujiVaultContract.proxyAddress]: fujiVaultContract,
      },
      tokens: {
        agx: fujiAgx,
        [fujiAgx.proxyAddress]: fujiAgx,

        aux: fujiAux,
        [fujiAux.proxyAddress]: fujiAux,

        lode: fujiLode,
        [fujiLode.proxyAddress]: fujiLode,

        burnedToken: {
          name: "Burned Token",
          address: "0x0000000000000000000000000000000000000000",
          symbol: "Burned",
          abi: [],
          logo: "https://lode.one/wp-content/uploads/2021/12/LODE-Token-1.png",
          decimals: 18,
          blockExplorer: "https://testnet.snowtrace.io/",
        },
        avax: {
          name: "Avalanche",
          decimals: 18,
          logo: avaxLogo,
          symbol: "AVAX",
          blockExplorer: "https://testnet.snowtrace.io/",
        },
      },
    },
  },
};

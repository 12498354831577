import * as React from 'react'
import { useContext } from 'react'
import './BalanceWidget.scss'
import { useWeb3React } from '@web3-react/core'
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
} from '@mui/material'
import { addressAsEllipses, formattedPrice } from '../../functions'
import { CurrencySelect } from './CurrencySelect'
import { CurrencyContext } from '../../CurrencyProvider'
import { LanguageSelect } from './LanguageSelect'
import { ERC20Config } from '../../environments/interface'
import { Refresh } from '@mui/icons-material'
import { ConfigContext } from '../../context/configProvider'
import { useBalanceContext } from '../../context/BalanceProvider'
import IconButton from '@mui/material/IconButton'
import { BigNumber, ethers } from 'ethers'
import { BlockExplorerListItem } from './BlockExplorerListItem'
import { TokenDetailsModal } from './TokenDetailModal'

type Anchor = 'top' | 'left' | 'bottom' | 'right'

/**
 * @param props {
 *  token: Token,
 *  tokenType: TokenType,
 *  anchor: Anchor,
 */
function BalanceWidgetListItem(props: {
  balance: string | number | BigNumber
  symbol: any
  currencyEquivalence: string
  disabled?: boolean
  tokenContractDetails?: ERC20Config
}) {
  let balance = props.balance
  if (typeof balance === 'string') balance = parseFloat(balance)
  if (typeof balance === 'number') balance = BigNumber.from(balance)
  if (balance instanceof BigNumber) balance = ethers.utils.formatEther(balance)

  // style balance to at most 4 decimal places and remove trailing zeros, and commas
  const formattedBalance = parseFloat(balance.toString()).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  })

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <img width={'100%'} height={'100%'} src={props.tokenContractDetails.logo} alt={''} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <span className={'balance-text-primary'} title={balance}>
            ~{formattedBalance}
          </span>
        }
        secondary={<span className={'balance-text-secondary'}>{props.currencyEquivalence}</span>}
      />
      <ListItemIcon>
        {' '}
        <TokenDetailsModal tokenDetails={props?.tokenContractDetails} />{' '}
      </ListItemIcon>
    </ListItem>
  )
}

export const BalanceWidget = ({
  open = true,
  handleClose = () => {},
}: {
  open?: boolean
  handleClose?: () => void
  connectButton?: React.ReactNode
}) => {
  // get agx balance
  const { account } = useWeb3React()
  // function to add ellipsis to the middle of an blockchain address
  const { currency } = useContext(CurrencyContext)
  const { networkConfig } = useContext(ConfigContext)
  const { marketPlaceConfig, nftConfig, vaultConfig } = networkConfig.contracts
  const { rawBalances: balances, prices, updaters } = useBalanceContext()
  const { agx, aux } = networkConfig.tokens

  if (!account || !networkConfig?.tokens) return undefined

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    handleClose()
  }

  return (
    <SwipeableDrawer
      anchor={'right'}
      open={open}
      onClose={toggleDrawer('right', false)}
      onOpen={toggleDrawer('right', true)}
    >
      <List
        sx={{
          width: 'min(420px, 80vw)',
          background: '#1D1F20',
          height: '100%',
          padding: 4,
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <List
          sx={{
            width: '100%',
            minWidth: 330,
            background: '#242627',
            paddingTop: 0,
          }}
          className={` radius-bottom ${!account ? 'disabled' : 'enabled'}`}
        >
          <ListItem disableGutters sx={{ color: 'white', bgcolor: '#dda826' }} className={'radius-top'}>
            <ListItemText
              primary={
                <div className={'wallet-balance-header'}>
                  <Typography variant={'h6'}>Wallet Balance</Typography>
                  <IconButton className={'float-right'} onClick={updaters.updateBalances}>
                    <Refresh />
                  </IconButton>
                </div>
              }
              secondary={`${!account ? 'No Wallet Connected' : addressAsEllipses(account)}`}
              secondaryTypographyProps={{
                component: 'a',
                href: `${networkConfig.blockExplorerUrl}/address/${account}`,
                target: '_blank',
              }}
              className={'mx-3'}
            />
          </ListItem>

          <BalanceWidgetListItem
            balance={balances.avax}
            currencyEquivalence={''}
            symbol={'avax'}
            tokenContractDetails={networkConfig.tokens.avax}
          />

          <BalanceWidgetListItem
            balance={balances.agx}
            currencyEquivalence={`${formattedPrice(balances.agx, prices.agx, currency)}`}
            symbol={'agx'}
            tokenContractDetails={networkConfig.tokens.agx}
          />
          <BalanceWidgetListItem
            balance={balances.aux}
            currencyEquivalence={`${formattedPrice(balances.aux, prices.aux, currency)}`}
            symbol={'aux'}
            tokenContractDetails={networkConfig.tokens.aux}
          />

          {/*contract 
          names and address links to explorer */}

          <ListItem disableGutters sx={{ color: 'white', bgcolor: '#000000' }} className={'radius-top'}>
            <ListItemText primary={'ERC20 Contract Details'} className={'mx-3'} />
          </ListItem>
          <BlockExplorerListItem config={agx} />
          <BlockExplorerListItem config={aux} />

          <ListItem disableGutters sx={{ color: 'white', bgcolor: '#000000' }} className={'radius-top'}>
            <ListItemText primary={'ERC721 Contract Details'} className={'mx-3'} />
          </ListItem>

          <BlockExplorerListItem config={nftConfig} />
          <BlockExplorerListItem config={marketPlaceConfig} />
          <BlockExplorerListItem config={vaultConfig} />
        </List>

        <List>
          <ListItem>
            <ListItemText primary={`Selected Currency: ${currency}`} className={'text-white'} />
            <CurrencySelect />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Language: EN-US`} className={'text-white'} />
            <LanguageSelect />
          </ListItem>
        </List>
      </List>
    </SwipeableDrawer>
  )
}

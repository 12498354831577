import { BigNumber, Contract, ContractTransaction } from 'ethers'

// marketplace contract functions
const unList =
  (contract: Contract) =>
  async (tokenIds: BigNumber[]): Promise<ContractTransaction> => {
    return await contract.unlist(tokenIds)
  }

const purchase =
  (contract: Contract) =>
  async (tokenIds: BigNumber[]): Promise<ContractTransaction> => {
    return await contract.purchase(tokenIds)
  }

const changeTokenPrice =
  (contract: Contract) =>
  async (tokenIds: BigNumber[], newPrice: BigNumber[]): Promise<ContractTransaction> => {
    return await contract.changeTokenPrice(tokenIds, newPrice)
  }

interface ListParams {
  tokenId: BigNumber
  askPrice: BigNumber
  expiryTime: BigNumber
  recipient: string
}

const list =
  (contract: Contract) =>
  async (listParams: ListParams[]): Promise<ContractTransaction> => {
    listParams.forEach((param) => {
      if (param.askPrice.isZero() || param.askPrice.isNegative()) {
        throw new Error('Price cannot be zero')
      }
    })

    let listData = listParams.map((param) => {
      return [param.tokenId, param.askPrice, param.expiryTime, param.recipient]
    })

    return await contract.list(listData)
  }

const whiteListed =
  (contract: Contract) =>
  async (account: string): Promise<boolean> => {
    return await contract.whitelisted(account)
  }

const setWhitelistStatus =
  (contract: Contract) =>
  async (state: boolean): Promise<ContractTransaction> => {
    return await contract.setWhitelistStatus(state)
  }

const whitelistActive = (contract: Contract) => async (): Promise<boolean> => {
  return await contract.whitelistActive()
}

interface Listing {
  owner: string
  recipient: string
  backedToken: string
  price: BigNumber
  expiryTime: BigNumber
  listed: boolean
}

const listings =
  (contract: Contract) =>
  async (tokenId: BigNumber): Promise<Listing> => {
    return await contract.listings(tokenId)
  }

export { unList, purchase, changeTokenPrice, list, whiteListed, whitelistActive, setWhitelistStatus, listings }

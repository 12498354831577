import React from "react";
import { jsx } from "@emotion/react";
import "./OwnershipBadge.scss";
import JSX = jsx.JSX;

export const OwnershipBadge = (props: {
  isLoading: boolean;
  showBadge: boolean;
  children: JSX.Element;
}): JSX.Element => {
  const badgeProps = { ...props };
  delete badgeProps.isLoading;

  if (props.showBadge && !props.isLoading)
    return (
      <div className={"box"}>
        <div className="ribbon">
          <span>Owner</span>
        </div>
        {props.children}
      </div>
    );
  return props.children;
};

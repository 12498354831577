import { useLocalStorage } from "../../hooks";
import { SupportedCurrency } from "../../interfaces/currencyInterfaces";
import * as React from "react";
import { useContext, useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  SwipeableDrawer,
} from "@mui/material";
import { supportedCurrencies } from "../../constants";
import { CurrencyContext } from "../../CurrencyProvider";
import { ArrowDropDown } from "@mui/icons-material";

export const CurrencySelect = () => {
  const { currency, dispatch } = useContext(CurrencyContext);

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  return (
    <Button variant={"outlined"} className={"pe-1"} onClick={toggle}>
      {currency} <ArrowDropDown />
      <SwipeableDrawer
        open={open}
        onClose={toggle}
        onOpen={() => {}}
        anchor={"right"}
      >
        <List>
          {supportedCurrencies.map((key) => (
            <ListItemButton
              key={key}
              onClick={() => {
                dispatch(key);
                toggle();
              }}
            >
              {key}
            </ListItemButton>
          ))}
        </List>
      </SwipeableDrawer>
    </Button>
  );
};

import React, { createContext, useContext, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

type ToastContextType = {
  loading: boolean | string;
  setLoading: (loading: boolean | string) => void;
};

const LoaderContext = createContext<ToastContextType | null>(null);

export const useLoaderDispatch = () => {
  return useContext(LoaderContext);
};

export const LoaderProvider = ({ children }: { children: React.ReactNode }) => {
  let [loading, setLoading] = useState<boolean | string>(false);

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      {loading ? (
        <Backdrop
          sx={{ color: "#43CAF1", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!!loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {children}
    </LoaderContext.Provider>
  );
};

import { initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";

export const [metaMask, hooks] = initializeConnector<MetaMask>(
  (actions) =>
    new MetaMask({
      onError: (error) => {
        console.error(error);
      },
      actions,
      options: {
        mustBeMetaMask: true,
      },
    })
);

import React, { createContext, useContext } from "react";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type positionType =
  | "top-center"
  | "bottom-center"
  | "top-right"
  | "bottom-right"
  | "top-left"
  | "bottom-left";

interface AppContextInterface {
  position: positionType;
  autoClose: any;
  hideProgressBar: boolean;
  newestOnTop: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
  draggable: boolean;
  progress: any;
  theme: any;
}

type ToastContextType = {
  showToast: ({
    type,
    message,
    duration,
    position,
  }: {
    type?: "warning" | "error" | "success" | "info" | "default" | "danger";
    message: string;
    duration?: number;
    position?: positionType;
  }) => void;
};

export const ToastContext = createContext<ToastContextType | null>(null);

export const useToastDispatch = () => {
  return useContext(ToastContext);
};

export const ToastProvider: React.FC = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // options -> { duration : 7000 } || { duration: false } || { duration: undefined }
  // change autoClose duration by specifing secs in 'duration' key
  // default value is 5 secs & if duration is undefined  default value will be applied
  // if {duration = false} toast won't hide until its manually close

  const showToast = ({ type, message, duration, position }) => {
    const toastObj: AppContextInterface = {
      position: position,
      autoClose: duration === undefined ? 5000 : duration,
      hideProgressBar: true,
      newestOnTop: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    };

    switch (type) {
      case "warning":
        toast.warn(message, toastObj);
        break;
      case "info":
        toast.info(message, toastObj);
        break;
      case "error":
        toast.error(message, toastObj);
        break;
      case "success":
        toast.success(message, toastObj);
        break;
      default:
        break;
    }
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
};

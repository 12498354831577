import { useWeb3React } from "@web3-react/core";
import { UnAuthorizedPage } from "../pages/unAuthPage/unAuthorizedPage";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

export const AnimateConnecting = ({ children }: { children: JSX.Element }) => {
  const { isActivating } = useWeb3React();
  const [isLoading, setIsLoading] = useState(false);

  const enableAnimatedLoading = (seconds: number) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, seconds * 1000);
  };

  useEffect(() => {
    if (isActivating) {
      enableAnimatedLoading(2);
    }
  }, [isActivating]);

  if (isLoading) {
    return (
      <UnAuthorizedPage
        pageHeading="Connect Wallet"
        text={"Connecting to your wallet..."}
        component={<CircularProgress disableShrink />}
      />
    );
  }

  return children;
};

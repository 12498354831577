import React from 'react'

import OnramperWidget from '@onramper/widget'
import { useWeb3React } from '@web3-react/core'
import { LoaderProvider } from '../context/loaderContext'
const onramperkey = process.env.REACT_APP_ONRAMPER_API_KEY

export default function OnRamper() {
  const { account } = useWeb3React()

  if (!account) return <></>
  const wallets = {
    ETH: { address: account },
  }

  return (
    <LoaderProvider>
      <div
        style={{
          width: '482px',
          height: '660px',
        }}
      >
        <OnramperWidget
          API_KEY={onramperkey}
          isAddressEditable={true}
          defaultAddrs={wallets}
          filters={{
            onlyCryptos: ['AVAX', 'USDC'],
          }}
          darkMode={true}
        />
      </div>
    </LoaderProvider>
  )
}

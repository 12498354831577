import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import logo from '../../resources/img/logo.png'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ConnectModalButton } from '../connect/ConnectModal'
import React, { useContext, useEffect, useState } from 'react'
import './NavBar.scss'
import Toolbar from '@mui/material/Toolbar'
import { BalanceWidget } from '../balanceWidget/BalanceWidget'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import { useWeb3React } from '@web3-react/core'
import { MetalType, TokenMetaData } from '../../interfaces/interfaces'
import fbicon from '../../resources/icons/fb.png'
import insticon from '../../resources/icons/insta.png'
import medicon from '../../resources/icons/med.png'
import teleicon from '../../resources/icons/tele.png'
import utubeicon from '../../resources/icons/youtube.png'
import twiticon from '../../resources/icons/twit.png'
import inicon from '../../resources/icons/in.png'
import { TokenListContext } from '../../context/tokenListProvider'
import { useToastDispatch } from '../../context/toastContext'
import { MetaMask } from '@web3-react/metamask'
import { WalletConnect } from '@web3-react/walletconnect'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { CircularProgress } from '@mui/material'

const metaMaskIcon = require('../../resources/img/metamask.png')
const coinBaseIcon = require('../../resources/img/coinbasewallet.png')
const walletConnectIcon = require('../../resources/img/walletconnect.png')
const paths = [
  {
    path: '/vault-status',
    name: 'Vault Status',
  },
]
const GetProviderIcon = ({ providerType }) => {
  let icon = ''

  if (providerType instanceof MetaMask) {
    icon = metaMaskIcon
  } else if (providerType instanceof WalletConnect) {
    icon = walletConnectIcon
  } else if (providerType instanceof CoinbaseWallet) {
    icon = coinBaseIcon
  }

  if (icon) return <img src={icon} alt="wallet" className="wallet-icon-button" />

  return <AccountBalanceWalletOutlinedIcon className={`wallet-icon-button `} />
}
export const NavBar = () => {
  const location = useLocation()
  const [open, setOpen] = React.useState(false)
  const { account, connector, isActive } = useWeb3React()
  // check if provider is through metamask, walletconnect, or coinbase wallet

  const { mintedTokens, loadingMinted } = useContext(TokenListContext)
  const navigate = useNavigate()

  const [show, setShow] = useState(false)
  const [expandMenu, setExpandMenu] = React.useState(false)

  const showDropdown = (e) => {
    setShow(!show)
  }
  const hideDropdown = (e) => {
    setShow(false)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const toggleMenu = () => {
    if (window.matchMedia('(max-width: 1024px)').matches) {
      setExpandMenu(!expandMenu)
    }
  }
  const activePath = React.useMemo(() => {
    return location.pathname
  }, [location.pathname])

  // filters can be any key from Token.metadata
  const goToProductPage = (filters?: Partial<TokenMetaData>) => {
    setShow(!show)

    if (filters) {
      toggleMenu()
      const filterArray = Object.entries(filters)
      const filteredTokens = mintedTokens.filter((token) => {
        return filterArray.every((param) => {
          return token.metadata[param[0]] === param[1]
        })
      })

      if (filteredTokens.length > 0 && filterArray.length > 0) {
        navigate(`/purchase/${filteredTokens[0].tokenId}`)
        return
      }
    }
    navigate('/purchase')
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg={'dark'} variant="dark" expanded={expandMenu}>
      <Toolbar className={'main-nav'}>
        <Navbar.Brand
          as={Link}
          to={'/'}
          className={'d-inline-flex'}
          onClick={() => {
            toggleMenu()
          }}
        >
          <img src={logo} alt="logo" className="navbar-logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            toggleMenu()
          }}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Container className="nav-container">
            <Nav className="justify-content-end">
              <div className={'nav-connect-end-wrapper '}>
                <div className="my-wallet-btn">
                  <Link
                    key={'wallet'}
                    to={'/my-wallet'}
                    className={`nav-link m-auto text-start  ${account ? 'gold-text' : 'disabled'}`}
                    data-bs-toggle="collapse"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (account) {
                        toggleMenu()
                        handleDrawerOpen()
                      }
                    }}
                  >
                    My Wallet
                  </Link>
                </div>


                {paths.map((route, index) => (
                  <Link
                    key={index}
                    to={route.path}
                    className={`nav-link text-start  ${activePath === route.path ? 'active' : ''}`}
                    data-bs-toggle="collapse"
                    onClick={() => {
                      toggleMenu()
                    }}
                  >
                    {route.name}
                  </Link>
                ))}
              </div>
            </Nav>
          </Container>

          <Nav className={'nav-connect-button-wrapper'}>
            <div className={'nav-connect-end-wrapper'}>
              {' '}
              <Nav
                className={`nav-connect-button-wrapper wallet-icon ${account ? 'gold-background' : 'disabled'}`}
                onClick={account ? handleDrawerOpen : () => {}}
              >
                <GetProviderIcon providerType={isActive ? connector : undefined} />
              </Nav>
              <Nav className={'nav-connect-button-wrapper'}>
                <ConnectModalButton />
              </Nav>
              <BalanceWidget open={open} handleClose={handleDrawerClose} />
            </div>
          </Nav>
          <div className="social-media-icons">
            <img src={fbicon} />
            <img src={insticon} />
            <img src={twiticon} />
            <img src={utubeicon} />
            <img src={teleicon} />
            <img src={medicon} />
            <img src={inicon} />
          </div>
        </Navbar.Collapse>{' '}
      </Toolbar>
    </Navbar>
  )
}

import React from "react";
import "./unAuthorized.scss";

export const UnAuthorizedPage = ({
  pageHeading,
  pageSubHeader,
  text,
  loading,
  component,
}: {
  pageHeading: string;
  pageSubHeader?: string;
  text: string;
  loading?: boolean;
  component?: JSX.Element;
}) => {
  if (loading) {
    return (
      <div className="unAuthorized">
        <h1>{pageHeading}</h1>
        <h3>{pageSubHeader}</h3>
        <p>{text}</p>
        <div className="loading">
          <div className="loading__dot"></div>
          <div className="loading__dot"></div>
          <div className="loading__dot"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="unAuth-page">
      <h1 className="heading-text">{pageHeading}</h1>
      {pageSubHeader && <h3 className="heading-sub-text">{pageSubHeader}</h3>}
      <div className="connection-info my-5">{text}</div>
      <div style={{ display: "inline-block" }}>{component}</div>
    </div>
  );
};

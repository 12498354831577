import * as React from "react";
import "./MainPageLayout.scss";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";

// import image import {NavBar} from "./Routes";
export default function MainPageLayout() {
  return (
    <>
      <Container>
        <Outlet />
      </Container>
    </>
  );
}

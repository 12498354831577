import React from 'react'
import './Home.scss'
import { HomeHeader } from './HomeHeader'
import VerticalLinearStepper from '../../components/Stepper'

export function Home() {
  return (
    <div className={'home-page'}>
      <VerticalLinearStepper />
    </div>
  )
}

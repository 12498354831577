import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { initializeConnector } from "@web3-react/core";

export const [coinbaseWallet, hooks] = initializeConnector<CoinbaseWallet>(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
        appName: "Coinbase Wallet",
        overrideIsMetaMask: false,
        appLogoUrl:
          "https://s3.amazonaws.com/coinbase-assets/wallet-logo/coinbase-wallet-logo-large.png",
      },
    })
);

import * as React from "react";
import { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { SupportedCurrency } from "../interfaces/currencyInterfaces";
import {
  MetalType,
  PossiblePrices,
  Quote,
  TokenType,
} from "../interfaces/interfaces";

import { TokenListContext } from "../context/tokenListProvider";

const parseCurrencyPriceFromQuotes = (
  quotes: Quote[],
  currency: SupportedCurrency | undefined
) => {
  const quote = quotes.find((quote) => quote.currency === currency);
  return { [quote.symbol]: quote.lastPrice };
};

export const useFetchAssetInCurrency = ({
  currency,
}: {
  asset?: MetalType | TokenType | undefined;
  currency: SupportedCurrency;
}): PossiblePrices => {
  const [price, setPrice] = useState({});

  async function updatePrice() {
    const response = await fetch("https://members.lode.one/api/publicinfo");
    const { data } = await response.json();
    const { agx, aux } = data.oracle;

    let prices = [agx, aux].map((data) => {
      return parseCurrencyPriceFromQuotes(data.quotes, currency);
    });
    const silverPrice = Number(
      prices.find((p) => Object.keys(p).includes("silver")).silver
    );

    const goldPrice = Number(
      prices.find((p) => Object.keys(p).includes("gold")).gold
    );

    prices.push({
      agx: (silverPrice * Number(data.agx_rate)).toString(),
      aux: (goldPrice * Number(data.aux_rate)).toString(),
      lode: (silverPrice * Number(data.lode_rate)).toString(),
    });

    setPrice(
      _.reduce(
        prices,
        function (memo, current) {
          return _.assign(memo, current);
        },
        {}
      )
    );
  }
  // fetch data from server
  useEffect(() => {
    // if (currency)
    updatePrice();
  }, [currency]);

  return price;
};

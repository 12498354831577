import * as React from "react";
import { useEffect, useState } from "react";

import "../components/navBar/NavBar.scss";
import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import { useContract } from ".";
import { ERC20Config } from "../environments/interface";
import { UseFetchBalanceResponse } from "./useFetchAvaxBalance";

export const useFetchTokenBalance = (
  contract_info: ERC20Config,
  decimals = 4
): UseFetchBalanceResponse => {
  const contract = useContract(contract_info);

  const { account, chainId } = useWeb3React();
  const [balance, setBalance] = useState("0");
  const [rawBalance, setRawBalance] = useState<BigNumber>(BigNumber.from(0));

  async function updateErc20Balance() {
    if (!contract || !account || !chainId) return;
    let balance = await contract?.balanceOf(account);
    const balanceString = ethers.utils.formatEther(balance);
    // round the string to 4 decimal places
    const roundedBalance = parseFloat(balanceString).toFixed(decimals);
    setBalance(roundedBalance);
    setRawBalance(balance);
    return balance;
  }

  useEffect(() => {
    updateErc20Balance();
  }, [account, chainId, contract]);

  return { balance, update: () => updateErc20Balance(), rawBalance };
};

// look in web3/contract_info.ts for the contract info
import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { Token } from '../interfaces/interfaces'
import { ERC20Config, ERC721Config } from '../environments/interface'
import { Signer } from '@ethersproject/abstract-signer'
import { Provider } from '@ethersproject/abstract-provider'
import { config } from '../environments/environments'

export function useTokenContract(token: ERC20Config): ethers.Contract {
  const { account, isActive, connector, chainId, provider } = useWeb3React()

  return React.useMemo(() => {
    if (isActive && account) {
      return new ethers.Contract(
        token.proxyAddress ?? token.address,
        token.abi,
        account ? provider.getSigner(account) : provider
      )
    }
    return undefined
  }, [isActive, account, chainId, connector, token])
}

export function getContract(
  token: Token | ERC20Config | ERC721Config | string,
  signerOrProvider: Signer | Provider,
  chainId?: number
): ethers.Contract {
  let address
  let abi

  if (!token || !signerOrProvider) throw new Error('Invalid arguments')

  if (typeof token === 'string') {
    if (!chainId) throw new Error('chainId is required when token is a string')
    address = token
    abi = config.networks[chainId].tokens[token].abi
  }

  if (token?.['tokenConfig']) {
    token = token['tokenConfig']
  }

  if (token?.['address']) {
    address = token?.['proxyAddress'] ?? token?.['address']
    abi = token?.['abi']
  }
  return new ethers.Contract(address, abi, signerOrProvider)
}

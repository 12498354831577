import { UnAuthorizedPage } from "../pages/unAuthPage/unAuthorizedPage";
import React, { useContext } from "react";
import { ConfigContext } from "../context/configProvider";
import { Grid, List, ListItemText } from "@mui/material";
import { useGetRoles } from "../hooks";
import { withProviders } from "../context/withProviders";
import { AnimateConnecting } from "./AnimateConnecting";
import { RequireConnection } from "./RequireConnection";
import { Check, Close } from "@mui/icons-material";

function ListFromObject({
  object,
  header,
}: {
  object: { [key: string]: string };
  header: string;
}) {
  return (
    <div className={"text-light"}>
      <h4>{header}</h4>
      <List>
        {Object.entries(object).map(([key, value]) => (
          <ListItemText
            key={header + key}
            primary={key}
            secondary={
              value ? <Check color={"success"} /> : <Close color={"error"} />
            }
          />
        ))}
      </List>
    </div>
  );
}

export const Component = ({ children }: { children: JSX.Element }) => {
  const { networkConfig } = useContext(ConfigContext);

  const nftRoles = useGetRoles(networkConfig?.contracts?.nftConfig);
  const vaultRoles = useGetRoles(networkConfig?.contracts?.vaultConfig);
  const marketPlaceRoles = useGetRoles(
    networkConfig?.contracts?.marketPlaceConfig
  );

  if (!nftRoles["MINTER_ROLE"]) {
    return (
      <UnAuthorizedPage
        pageHeading="Admin Only"
        text="You are not authorized to view this page."
        component={
          <>
            <Grid container spacing={2}>
              <Grid item>
                <ListFromObject object={nftRoles} header={"NFT Contract"} />
              </Grid>
              <Grid item>
                <ListFromObject object={vaultRoles} header={"Vault Contract"} />
              </Grid>
              <Grid item>
                <ListFromObject
                  object={marketPlaceRoles}
                  header={"Market Place"}
                />
              </Grid>
            </Grid>
          </>
        }
      />
    );
  }

  return children;
};
export const RequireOwner = withProviders([
  AnimateConnecting,
  RequireConnection,
])(Component);

import React from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";

export const PurchasePageButton = (
  (ButtonComponent) =>
  (
    props: ButtonProps & {
      isLoading: boolean;
      text: any;
    }
  ) => {
    const buttonProps = { ...props };
    delete buttonProps.isLoading;
    delete buttonProps.text;

    return (
      <ButtonComponent
        onClick={props.onClick}
        disabled={props.disabled || props.isLoading}
        variant="contained"
        size="medium"
        className="collection-buttons"
        {...buttonProps}
        startIcon={
          props.isLoading ? <CircularProgress color={'inherit'} size={20} /> : props.startIcon
        }
      >
        {props.text}
      </ButtonComponent>
    );
  }
)(Button);

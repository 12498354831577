import * as React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import OnRamper from './OnRamper'
import './Stepper.scss'
import { useWeb3React } from '@web3-react/core'
import Connect from './connect/Connect'
import { useEffect } from 'react'

const steps = [
  {
    label: 'Connect Wallet',
    description: `In order to get AGX, first you will first need to buy some USDC`,
    Component: Connect,
  },
  {
    label: 'Buy some USDC - Current Balance: 0',
    description: `In order to get AGX, first you will first need to buy some USDC`,
    Component: OnRamper,
  },
  // {
  //   label: 'Create an ad group',
  //   description: 'An ad group contains one or more ads which target a shared set of keywords.',
  //   Component: () => <></>,
  // },
  // {
  //   label: 'Create an ad',
  //   description: `Try out different ad text to see what brings in the most customers,
  //             and learn how to enhance your ads using features like ad extensions.
  //             If you run into any problems with your ads, find out how to tell if
  //             they're running and how to resolve approval issues.`,
  //   Component: () => <></>,
  // },
]

export default function VerticalLinearStepper() {
  const { account, isActivating, isActive } = useWeb3React()

  const [activeStep, setActiveStep] = React.useState(account ? 1 : 0)

  useEffect(() => {
    if (isActive) {
      if (activeStep === 0) setActiveStep(1)
    } else {
    }
  }, [isActive])

  const handleNext = () => {
    if (!isActive) return setActiveStep(0)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <Box sx={{ maxWidth: 600, color: 'white' }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => {
          return (
            <Step key={step.label} className={'step-label'}>
              <StepLabel
                className={'step-label'}
                optional={
                  index === 2 ? (
                    <Typography variant="overline" color={'white'}>
                      Last step
                    </Typography>
                  ) : null
                }
              >
                <Typography color={'white'}>
                  {step.label} -{' '}
                  {index === 0 && (
                    <Typography variant={'subtitle2'} color={'tan'}>
                      {account}
                    </Typography>
                  )}
                </Typography>
              </StepLabel>
              <StepContent>
                <step.Component />
                <Typography color={'white'}>{step.description}</Typography>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      color={'error'}
                      variant="contained"
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }} disabled={!isActive}>
                      {index === steps.length - 1 ? 'Finish' : 'Continue'}
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          )
        })}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  )
}

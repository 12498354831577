// import { CoinbaseWallet:CoinbaseWallet } from '@web3-react/coinbase-wallet'

export const supportedChainIds = [43113, 43114];
//
// export const generalProvider = new ethers.providers.JsonRpcProvider(
//   "https://api.avax.network/ext/bc/C/rpc"
// );
//
// export const CoinbaseWallet = new CoinbaseWallet({
//   url: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
//   appName: "Coinbase Wallet",
//   appLogoUrl:
//     "https://s3.amazonaws.com/coinbase-assets/wallet-logo/coinbase-wallet-logo-large.png",
//   darkMode: false,
//   supportedChainIds,
// });
//
// export const WalletConnect = new WalletConnectConnector({
//   infuraId: `${process.env.REACT_APP_INFURA_ID}`,
//   bridge: "https://bridge.walletconnect.org",
//   qrcode: true,
//   supportedChainIds,
//   rpc: {
//     "43113": `https://api.avax-test.network/ext/bc/C/rpc`,
//     "43114": `https://api.avax.network/ext/bc/C/rpc`,
//   },
// });
//
// export const Injected = new InjectedConnector({
//   supportedChainIds,
// });

import React, { createContext, useContext } from "react";

import {
  useFetchAssetInCurrency,
  useFetchAvaxBalance,
  useFetchTokenBalance,
} from "../hooks";

import { ConfigContext } from "./configProvider";

import { CurrencyContext } from "../CurrencyProvider";
import { BigNumber } from "ethers";

interface Balances {
  agx: string;
  aux: string;
  avax: string;
}

interface RawBalances {
  agx: BigNumber;
  aux: BigNumber;
  avax: BigNumber;
}

interface Prices {
  agx: number;
  aux: number;
}

interface Updaters {
  agx: () => Promise<BigNumber>;
  aux: () => Promise<BigNumber>;
  avax: () => Promise<BigNumber>;
  updateBalances: () => Promise<void>;
}

interface BalanceProviderResponse {
  balances: Balances;
  rawBalances: RawBalances;
  prices: Prices;
  updaters: Updaters;
}
const BalanceContext = createContext(null);

export const useBalanceContext = (): BalanceProviderResponse => {
  return useContext(BalanceContext);
};

export const BalanceProvider = ({ children }) => {
  const { networkConfig } = useContext(ConfigContext);
  const { currency } = useContext(CurrencyContext);

  const { agx, aux } = networkConfig.tokens;

  const {
    balance: agxBalance,
    update: updateAgx,
    rawBalance: agxRawBalance,
  } = useFetchTokenBalance(agx);
  const {
    balance: auxBalance,
    update: updateAux,
    rawBalance: auxRawBalance,
  } = useFetchTokenBalance(aux);
  const {
    balance: avaxBalance,
    update: updateAvax,
    rawBalance: avaxRawBalance,
  } = useFetchAvaxBalance();

  const updateBalances = async () => {
    await Promise.all([updateAgx(), updateAux(), updateAvax()]);
  };

  const { agx: agxPrice, aux: auxPrice } = useFetchAssetInCurrency({
    currency,
  });

  const balances: Balances = {
    agx: agxBalance,
    aux: auxBalance,
    avax: avaxBalance,
  };

  const rawBalances: RawBalances = {
    agx: agxRawBalance,
    aux: auxRawBalance,
    avax: avaxRawBalance,
  };
  const prices: Prices = { agx: agxPrice, aux: auxPrice };

  const updaters: Updaters = {
    agx: updateAgx,
    aux: updateAux,
    avax: updateAvax,
    updateBalances,
  };

  return (
    <BalanceContext.Provider
      value={{ balances, rawBalances, prices, updaters }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

import React from "react";
import "./App.scss";
import {
  BrowserRouter,
  Navigate,
  useLocation,
  useRoutes,
} from "react-router-dom";

import MainPageLayout from "./layouts/MainPageLayout";
import { Home } from "./pages/home/Home";

import { NavBar } from "./components/navBar/NavBar";
import PurchasePageLayout from "./layouts/PurchasePageLayout";
import { Web3Provider } from "@ethersproject/providers";
import { NFTMintForm } from "./components/NFTMintForm/NFTMintForm";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CurrencyProvider } from "./CurrencyProvider";
import TokenListProvider from "./context/tokenListProvider";
import { VaultStatusPage } from "./pages/vaultstatus/Vaultstatus";

import MyCollectionPageLayout from "./layouts/MyCollectionPageLayout";
import { MyCollection } from "./pages/mycollection/mycollection";

import { PageNotFoundView } from "./pages/pageNotFound/PageNotFound";
import { LearnMore } from "./pages/learnMore/LearnMore";

import { PurchaseItem } from "./pages/purchase/purchaseItem/PurchaseItem";
import { PurchaseHome } from "./pages/purchase/purchaseHome/PurchaseHome";
import { ToastProvider } from "./context/toastContext";
import { BalanceProvider } from "./context/BalanceProvider";
import { withProviders } from "./context/withProviders";
import { RouteObject } from "react-router/dist/lib/context";
import { RequireConnection } from "./guards/RequireConnection";
import { LoaderProvider } from "./context/loaderContext";
import ConfigProvider from "./context/configProvider";
import ContractTestingProvider from "./context/contractTestingProvider";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { Web3ReactHooks, Web3ReactProvider } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { Network } from "@web3-react/network";
import { WalletConnect } from "@web3-react/walletconnect";
import {
  coinbaseWallet,
  hooks as coinbaseWalletHooks,
} from "./web3/connectors/coinbaseWallet";
import { hooks as metaMaskHooks, metaMask } from "./web3/connectors/metaMask";
import { hooks as networkHooks, network } from "./web3/connectors/network";
import {
  hooks as walletConnectHooks,
  walletConnect,
} from "./web3/connectors/walletConnect";
import { Footer } from "./layouts/Footer/Footer";
import { RequireOwner } from "./guards/RequireOwner";
import { WhiteList } from "./guards/WhiteList";
import { useLocalStorage } from "./hooks";
// asset backed token

const mainRoutes: RouteObject = {
  path: "/",
  element: <MainPageLayout />,
  children: [
    { path: "*", element: <Navigate to="/404" /> },
    { path: "/", element: <Home /> },
    {
      path: "404",
      element: <PageNotFoundView />,
    },
  ],
};

const learnMoreRoutes: RouteObject = {
  path: "info",
  element: <MainPageLayout />,
  children: [
    { path: "*", element: <Navigate to="/404" /> },
    { path: "/info", element: <LearnMore /> },
  ],
};

const purchaseRoutes: RouteObject = {
  path: "purchase",
  element: (
    <RequireConnection>
      <WhiteList>
        <PurchasePageLayout />
      </WhiteList>
    </RequireConnection>
  ),
  children: [
    { path: "*", element: <Navigate to="/404" /> },
    {
      path: "/purchase",
      element: <PurchaseHome />,
    },
    {
      path: ":id",
      element: <PurchaseItem />,
    },
  ],
};

const mintRoutes: RouteObject = {
  path: "mint",
  element: (
    <RequireConnection>
      <RequireOwner>
        <MainPageLayout />
      </RequireOwner>
    </RequireConnection>
  ),
  children: [
    { path: "*", element: <Navigate to="/404" /> },
    {
      path: "/mint",
      element: <NFTMintForm />,
    },
  ],
};

const vaultStatusRoutes: RouteObject = {
  path: "vault-status",
  element: <MainPageLayout />,
  children: [
    { path: "*", element: <Navigate to="/404" /> },
    { path: "/vault-status", element: <VaultStatusPage /> },
  ],
};
const myCollectionRoutes: RouteObject = {
  path: "my-collection",
  element: (
    <RequireConnection>
      <MyCollectionPageLayout />{" "}
    </RequireConnection>
  ),
  children: [
    { path: "*", element: <Navigate to="/404" /> },
    {
      path: "/my-collection",
      element: <MyCollection />,
      errorElement: <PageNotFoundView />,
    },
    {
      path: ":id",
      element: <PurchaseItem collectionView={true} />,
    },
  ],
};

export function getLibrary(provider): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

const connectors: [
  MetaMask | WalletConnect | CoinbaseWallet | Network,
  Web3ReactHooks
][] = [
  [walletConnect, walletConnectHooks],
  [metaMask, metaMaskHooks],
  [coinbaseWallet, coinbaseWalletHooks],
  [network, networkHooks],
];

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#000",
      },
      warning: {
        main: "#DFA926",
        contrastText: "#fff",
      },
    },
  });
  const Routes = () => {
    return useRoutes([
      mainRoutes,
      vaultStatusRoutes,
    ]);
  };

  function ScrollToTop() {
    const { pathname } = useLocation();
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  const [localStorageProvider] = useLocalStorage("provider", "");

  const connectorOverrides = localStorageProvider
    ? connectors.find(
        ([connector]) => connector.constructor.name === localStorageProvider
      )?.[0]
    : undefined;

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <Web3ReactProvider
          connectors={connectors}
          connectorOverride={connectorOverrides}
        >
          <ConfigProvider>
            <LoaderProvider>
              <TokenListProvider>
                <ContractTestingProvider>
                  <CurrencyProvider>
                    <BalanceProvider>
                      <NavBar />
                      <Routes />
                    </BalanceProvider>
                  </CurrencyProvider>
                </ContractTestingProvider>
              </TokenListProvider>
            </LoaderProvider>
          </ConfigProvider>
        </Web3ReactProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default withProviders(ToastProvider)(App);

import * as React from "react";

import "../components/navBar/NavBar.scss";
import { useEffect, useRef } from "react";

export const useScrolledByBool = (heightThreshold) => {
  // when the user scrolls down, hide the navbar. When the user scrolls up, show the navbar
  const [scrolled, setScrolled] = React.useState(false);

  // keep track of the last scroll position
  const lastScrollY = useRef(undefined);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (lastScrollY.current === undefined) {
      lastScrollY.current = currentScrollY;
    }

    if (heightThreshold) {
      // if the user has scrolled down more than the threshold, set scrolled to true
      if (currentScrollY > lastScrollY.current + heightThreshold) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    } else {
      // if the user has scrolled down, set scrolled to true
      if (currentScrollY > lastScrollY.current) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scrolled;
};

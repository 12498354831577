import { createContext, useEffect } from "react";
import { config } from "../environments/environments";
import { useWeb3React } from "@web3-react/core";
import { useLocalStorage } from "../hooks";

export type ConfigContextType = {
  networkConfig: typeof config.networks[43114] | typeof config.networks[43113];
  desiredChainId: 43113 | 43114 | number;
};

export const ConfigContext = createContext<ConfigContextType | null>(null);

const ConfigProvider = ({ children }) => {
  let web3 = useWeb3React();
  const { connector } = web3;
  const [localStorageProvider] = useLocalStorage("provider", "");

  // attempt to connect eagerly on mount
  useEffect(() => {
    if (localStorageProvider === "CoinbaseWallet") {
      localStorage.removeItem(
        "-walletlink:https://www.walletlink.org:Addresses"
      );
    }

    if (localStorageProvider === connector?.constructor?.name)
      void connector.connectEagerly(config.desiredChainId);
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        networkConfig: config.networks[config.desiredChainId],
        desiredChainId: config.desiredChainId,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;

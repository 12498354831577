import React from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import {
  Badge,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material'
import { Token } from '../../../interfaces/interfaces'
import { displayTokensWeight } from '../../../functions'
import '../../mycollection/mycollection.scss'
import './SpotLightCard.scss'
import {
  ConfirmationNumber,
  ConfirmationNumberOutlined,
  Construction,
  Fingerprint,
  Interests,
  MyLocation,
  Percent,
  PermIdentity,
  SvgIconComponent,
  Title,
} from '@mui/icons-material'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
interface SpotLightCard {
  tokens: Token[] | undefined
}

export default function ListItemIconPopover({ children, icon }: { children: any; icon: SvgIconComponent }) {
  const Icon = icon

  return (
    <ListItemIcon>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div>
            <Icon {...bindTrigger(popupState)} />
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography sx={{ p: 2 }}>{children}</Typography>
            </Popover>
          </div>
        )}
      </PopupState>
    </ListItemIcon>
  )
}

export const spotLightCardTemplate =
  (toPath: 'my-collection' | 'purchase') =>
  ({ tokens }: SpotLightCard) => {
    const [index, setIndex] = React.useState(0)
    const token = React.useMemo(() => {
      return tokens?.[index]
    }, [tokens, index])

    const navigate = useNavigate()

    const isMyCollection = toPath === 'my-collection'
    if (!token || !token.metadata) return <></>

    const metalType = token.tokenConfig.symbol === 'AUX' ? 'gold' : 'silver'

    const navigateToProduct = () => {
      navigate(`/${toPath}/${token.tokenId}`)
    }

    const { displayWeightUnit, shape, image, currentLocation, mintLocation, mintYear, serial, fineness, title } =
      token.metadata

    const multiple = tokens.length > 1

    return (
      <>
        <Card
          raised
          sx={{ maxWidth: 300 }}
          className={`spotlight-card ${metalType}-bar ${multiple && 'metal-bar-card-multiple'}`}
          onClick={navigateToProduct}
        >
            <b> this card is a work in progress </b>

          <CardActionArea className={'spotlight-card-body'}>
            <div className={'media-container'}>
              <CardMedia component="img" height="140" image={image} alt="green iguana" />
            </div>
            <Typography variant="body2" textTransform={'capitalize'} className={'spotlight-card-title'}>
              <span>
                {displayTokensWeight(token)}
                {displayWeightUnit} {metalType} {shape}
              </span>{' '}
            </Typography>
            <CardContent className={'spotlight-card-content'}>
              <List disablePadding={true} className={'spotlight-card-content-list'}>
                <ListItem>
                  <ListItemIconPopover icon={Title}>Title given to the TRP at mint</ListItemIconPopover>
                  <ListItemText primary={title} />
                </ListItem>
                <ListItem>
                  <ListItemIconPopover icon={Fingerprint}>Unique identifier of the TRP</ListItemIconPopover>
                  <ListItemText primary={serial} />
                </ListItem>
                <ListItem>
                  <ListItemIconPopover icon={Percent}>Fineness of the TRP</ListItemIconPopover>
                  <ListItemText primary={fineness} />
                </ListItem>
                <ListItem>
                  <ListItemIconPopover icon={MyLocation}>Current location of the TRP</ListItemIconPopover>
                  <ListItemText primary={currentLocation} />
                </ListItem>
                <ListItem>
                  <ListItemIconPopover icon={Construction}>Location where the TRP was forged</ListItemIconPopover>
                  <ListItemText primary={mintLocation} secondary={mintYear} />
                </ListItem>
              </List>
            </CardContent>
          </CardActionArea>
          <CardActions className={'spotlight-card-footer'} hidden={!isMyCollection}>
            <Button size="small" color="inherit" variant={'outlined'}>
              Redeem
            </Button>
          </CardActions>
        </Card>
      </>
    )
  }
export const SpotLightCard = spotLightCardTemplate('purchase')

export const MyCollectionCard = spotLightCardTemplate('my-collection')

import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";

import "./PurchaseModal.scss";
import _ from "lodash";
import { CurrencyContext } from "../../../../CurrencyProvider";
import { useFetchAssetInCurrency } from "../../../../hooks/useFetchAssetInCurrency";
import { CircularProgress, Grow } from "@mui/material";
import { formattedPrice } from "../../../../functions";
import { useToastDispatch } from "../../../../context/toastContext";
import { Token } from "../../../../interfaces/interfaces";

export const PurchaseModal = ({
  show,
  handleClose,
  token,
}: {
  show: boolean;
  handleClose: () => void;
  token: Token;
}) => {
  const { currency } = useContext(CurrencyContext);
  const { showToast } = useToastDispatch();
  const [approvalWaiting, setApprovalWaiting] = useState(false);
  useEffect(() => {
    show && resetState();
  }, [show]);


  const assetPrices = useFetchAssetInCurrency({
    currency: currency,
  });

  const onPurchase = () => {
    setApprovalWaiting(true);
    showToast({
      type: "error",
      message: "You rejected the request in your wallet.",
      duration: undefined,
      position: "bottom-center",
    });
  };

  const resetState = () => {
    setApprovalWaiting(false);
  };
  if (!token) {
    return null;
  }

  return (
    <Modal show={show} onHide={handleClose} centered={true} size="lg">
      <div className="purhcase-modal p-3">
        <Modal.Body>
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <label className="title-text">Purchase Now</label>
              <div className="close-btn">
                <CloseIcon
                  className="close-button-icon cursor-pointer"
                  fontSize="large"
                  onClick={() => {
                    handleClose();
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-7 col-xs-12 ">
              <img
                src={token.metadata?.image}
                className="img-wrapper float-start"
                alt=""
              />
              <div className="float-start modal-poduct-details">
                <div className="title-1 text-start">
                  {" "}
                  {token.metadata?.metalType} &nbsp;
                  {token.metadata?.shape}
                </div>
                <div className="title-2">{token.metadata?.title}</div>
                <div className="title-3">Quantity: 1</div>
              </div>
            </div>
            <div className="col-lg-5 col-xs-12  text-xs-start text-lg-end  p-3 line-height-30 pt-3">
              <div className="title-1">Price</div>

              <div className="title-4">
                {" "}
                {token.listing.price}
                &nbsp;
                {_.upperCase(token.tokenConfig.symbol)}
              </div>

              <div className="title-1">
                {formattedPrice(
                  token.listing.price.toString(),
                  assetPrices?.agx,
                  currency
                ) ?? <CircularProgress size={"0.9em"} />}
              </div>
            </div>
          </div>
          {approvalWaiting && (
            <div className="row mt-3">
              <div className="col-12 title-2">Approve Transaction</div>
              <div className="col-12 mt-3">
                You will be asked to approve this purchase from your wallet. You
                only need to do this once.
              </div>
            </div>
          )}

          <div className="purchase-modal-footer">
            {!approvalWaiting && (
              <>
                <Button
                  className="purchase-modal-footer-buttons col-5"
                  variant="outlined"
                  onClick={handleClose}
                  size="large"
                >
                  Cancel
                </Button>
                <Button
                  className="purchase-modal-footer-buttons col-5"
                  size="large"
                  sx={{ background: "#DFA926", color: "#fff" }}
                  variant="contained"
                  onClick={onPurchase}
                >
                  Purchase Now
                </Button>
              </>
            )}
            {approvalWaiting && (
              <Button
                className="purchase-modal-footer-buttons col-5"
                size="large"
                sx={{ background: "#DFA926", color: "#fff" }}
                variant="contained"
                onClick={handleClose}
              >
                Continue
              </Button>
            )}
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

import { ERC721Config, TXLifeCycleViews } from '../../environments/interface'
import { BigNumber, Contract, ethers } from 'ethers'
import { Token } from '../../interfaces/interfaces'
import { approve, changeTokenPrice, purchase, redeemForTokens, setApprovalForAll, unList } from '../../web3'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ContractFunction } from '@ethersproject/contracts/src.ts'
import { UpdateToken } from '../../context/tokenListProvider'
import _ from 'lodash'

export function getTxButtonText(currentView: TXLifeCycleViews, defaultText = 'Un-List') {
  switch (currentView) {
    case 'pending-signature':
      return 'Awaiting user signature'
    case 'pending-block-confirmation':
      return 'Awaiting block confirmation'
    case 'pending-tx-confirmation':
      return 'Pending TX Confirmation'
    case 'error':
      return 'Error'
    case 'success':
      return 'success'
    default:
      return defaultText
  }
}

export interface TxLifeCycleProps extends TxLifeCyclePropsWithoutContractFunction {
  contractFunction: ContractFunction
}

export interface TxLifeCyclePropsWithoutContractFunction {
  setCurrentView: (val: TXLifeCycleViews) => any
  updateTokenIds: ({ ToTokenId, tokenIds, shouldToast }: UpdateToken) => void

  shouldToast?: boolean
  currentView: TXLifeCycleViews
  tokens?: Token[]
  token?: Token
}

export async function handleTxLifeCycle({
  contractFunction,
  setCurrentView,
  tokens,
  token,
  updateTokenIds,
  shouldToast,
}: TxLifeCycleProps) {
  let tokenIds: number[] = []
  //filter token id from array
  if (tokens?.length) {
    tokenIds = tokens.map((token) => token.tokenId)
  } else {
    tokenIds = [token.tokenId]
  }

  try {
    setCurrentView('pending-signature')
    const tx = await contractFunction()
    setCurrentView('pending-block-confirmation')
    let result = await tx?.wait(1)

    if (tokenIds && updateTokenIds) {
      await updateTokenIds({ tokenIds, shouldToast: shouldToast ?? true })
    }
    setCurrentView(undefined)

    return tx
  } catch (e) {
    if (updateTokenIds) await updateTokenIds({ tokenIds: tokenIds, shouldToast: shouldToast ?? false })

    setCurrentView(undefined)

    if (e?.reason) {
      return toast.error(_.startCase(e?.reason))
    }

    console.log(e)
    if (e?.code === 4001) {
      toast.error('Transaction rejected')
    }

    if (e?.code === -32603) {
      toast.error('Transaction rejected')
    }
    if (e?.code === -32000) {
      toast.error('Transaction rejected')
    }

    let message = e.error?.data?.message ?? e.message ?? 'Something Went Wrong, Please Try Again !'

    toast.error(message, {
      autoClose: 5000,
      position: 'top-center',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    })
    return e
  }
}

interface HandleUnListParams extends TxLifeCyclePropsWithoutContractFunction {
  marketplace: Contract
}

export async function handleUnList({
  marketplace,
  tokens,
  setCurrentView,
  updateTokenIds,
  currentView,
}: HandleUnListParams) {
  const tokenIds: BigNumber[] = tokens.map((token) => BigNumber.from(token.tokenId))
  await handleTxLifeCycle({
    setCurrentView,
    tokens,
    updateTokenIds,
    currentView,
    contractFunction: async () => await unList(marketplace)(tokenIds),
  })
}

interface HandleApproveAllowanceParams extends TxLifeCyclePropsWithoutContractFunction {
  tokenContract: Contract
  erc721Contract: ERC721Config
  amt: BigNumber
}

export async function handleApproveAllowance(params: HandleApproveAllowanceParams) {
  const amt = params.amt === undefined ? ethers.constants.MaxUint256 : params.amt
  return await handleTxLifeCycle({
    contractFunction: async () =>
      await approve(params.tokenContract)(params.erc721Contract.proxyAddress ?? params.erc721Contract.address, amt),
    ...params,
    shouldToast: false,
  })
}

interface HandleApproveTokenIdForRedemptionParams extends TxLifeCyclePropsWithoutContractFunction {
  nftContract: Contract
  vaultContract: ERC721Config
  tokenId: number
}

export async function handleApproveTokenIdForRedemption(
  params: HandleApproveTokenIdForRedemptionParams
): Promise<void> {
  await handleTxLifeCycle({
    contractFunction: async () =>
      await approve(params.nftContract)(
        params.vaultContract.proxyAddress ?? params.vaultContract.address,
        params.tokenId
      ),
    ...params,
  })
}

interface HandleApproveAllTokensForRedemptionParams extends TxLifeCyclePropsWithoutContractFunction {
  setCurrentView: (val: TXLifeCycleViews) => void
  nftContract: Contract
  vaultConfig: ERC721Config
  bool?: boolean
}

export async function handleApproveAllTokensForRedemption(params: HandleApproveAllTokensForRedemptionParams) {
  const bool = params.bool === undefined ? true : params.bool
  await handleTxLifeCycle({
    contractFunction: async () =>
      await setApprovalForAll(params.nftContract)(params.vaultConfig.proxyAddress ?? params.vaultConfig.address, bool),
    ...params,
  })
}

interface HandleRedeemForTokenParams extends TxLifeCyclePropsWithoutContractFunction {
  vaultContract: Contract
  token: Token
}
export async function handleRedeemForTokens(params: HandleRedeemForTokenParams) {
  await handleTxLifeCycle({
    contractFunction: async () => await redeemForTokens(params.vaultContract)(params.token.tokenId),
    setCurrentView: params.setCurrentView,
    ...params,
  })
}

interface HandlePurchaseParams extends TxLifeCyclePropsWithoutContractFunction {
  marketPlaceContract: Contract
  tokens: Token[]
}

export async function handlePurchase(params: HandlePurchaseParams) {
  const tokenIds: BigNumber[] = params.tokens.map((token) => BigNumber.from(token.tokenId))
  return await handleTxLifeCycle({
    contractFunction: async () => await purchase(params.marketPlaceContract)(tokenIds),
    tokens: params.tokens,
    ...params,
  })
}

interface GetAllowanceParams {
  tokenContract: Contract
  account: string
  erc721Config: ERC721Config
}

export async function getAllowance({ tokenContract, account, erc721Config }: GetAllowanceParams): Promise<string> {
  const allowance = await tokenContract.allowance(account, erc721Config.proxyAddress ?? erc721Config.address)

  return ethers.utils.formatEther(allowance)
}

export async function getApproved(
  tokenContract: Contract,
  tokenId: number,
  erc721Config: ERC721Config
): Promise<boolean> {
  const approved = await tokenContract.getApproved(tokenId)

  return approved === erc721Config.proxyAddress
}

interface HandleChangeTokenPriceParams extends TxLifeCyclePropsWithoutContractFunction {
  marketplace: Contract
  newPrices: any
}

export async function handleChangeTokenPrice({
  setCurrentView,
  marketplace,
  tokens,
  newPrices,
  currentView,
  updateTokenIds,
}: HandleChangeTokenPriceParams) {
  await handleTxLifeCycle({
    setCurrentView,
    currentView,
    tokens,
    updateTokenIds,
    contractFunction: async () => {
      const tokenIds: BigNumber[] = tokens.map((token) => BigNumber.from(token.tokenId))
      const prices: BigNumber[] = newPrices.map((newPrice) => numTo256(newPrice))
      await changeTokenPrice(marketplace)(tokenIds, prices)
    },
  })
}

export function u256ToNum(amt: BigNumber): number {
  if (!amt) {
    return 0
  }
  return BigNumber.from(amt).div(BigNumber.from(10).pow(18)).toNumber()
}

export const numTo256 = (amt: any): BigNumber => BigNumber.from(amt).mul(BigNumber.from(10).pow(18))
